import { LOCALES } from '../locales';

export default {
    [LOCALES.SPANISH]: {
        'hello': 'Hello',
        'language':'Español',
        'title': 'ECOSISTEMA FINANCIERO Descentralizado',
        'subtitle': 'Plataforma impulsada por Blockchain para garantizar financiación y ayudar a aumentar la esperanza de vida.',
        'menu-about': 'About',
        'menu-vision': 'Visión',
        'menu-benefits':'Benefits',
        'menu-auction':'Subasta',
        'menu-roadmap':'Roadmap',
        'menu-team':'Team',
        'menu-faq':'Faq',
        'menu-contact':'Contacto',
        'button-documents':'Documentación',
        'button-auction':'Subasta',
        'button-subscribe':'Suscribirse',
        'button-contribute':'Contribuir',
        'video-pop':'¿Como funciona?',
        'why-buy':'¿Porqué comprar tokens?',
        'about-ticker':'Sobre Metha Biofund',
        'about-title':'Nuestro objetivo es ayudar a aumentra la esperanza y calidad de vida.',
        'about-1':'A través de nuestra plataforma tratamos de crear un ecosistema descentralizado para resolver conflictos de interés en el campo de la investigación/industria farmacéutica.',
        'about-2':'Alinearemos los intereses de los contribuyentes y de las instituciones de investigación colocándolos bajo el paraguas de la divisa Metha, un token ERC20 en la cadena de bloques de Ethereum. ',
        'about-3':'Esta nueva moneda se distribuirá a diario en un proceso llamado subasta Metha y será la moneda de uso para tratamientos, patentes y curas resultantes de la investigación.',
        'vision-ticker-1':'El Problema',
        'vision-title-1':'Conflicto de Intereses',
        'vision-1-1':'Muchas instituciones de investigación, especialmente en el campo de la medicina preventiva y el envejecimiento, carecen de financiación.',
        'vision-1-2':'Caen en una categoría, en la que no son interesantes ni rentables para los gobiernos o las grandes farmacéuticas para financiar.',
        'vision-1-3':'Aquí es donde aparece Metha Biofund, para agruparlos en una gran industria de extensión de la vida con su propio sistema económico.',
        'vision-ticker-2':'DIVISA METHA.',
        'vision-title-2':'La subasta de Metha',
        'vision-2-1':'La nueva divisa Metha se extraerá mediante contribuciones en un proceso similar a una subasta. ',
        'vision-2-2':'Los tokens de Metha se distribuirán en pequeños lotes proporcionalmente de acuerdo con las contribuciones diarias.',
        'vision-2-3':'Esta subasta tendrá lugar mientras los tokens están en el mercado libre, esto asegurará contribuciones diarias que tenderán al precio por token en el mercado.',
        'vision-ticker-3':'ECOSISTEMA FINANCIERO',
        'vision-title-3':'Contratos inteligentes transparentes',
        'vision-3-1':'Nuestro ecosistema descentralizado proporcionará una toma de decisiones y una distribución de fondos totalmente transparentes.',
        'vision-3-2':'Los proyectos populares entre la comunidad serán votados en cadena por auditores y serán financiados gradualmente hasta su finalización.',
        'vision-3-3':'Los contribuyentes serán recompensados con sus tokens, la nueva moneda utilizada por las instituciones de investigación adheridas al programa.',
        'benefits-ticker':'ACTORES INVOLUCRADOS',
        'benefits-title':'Todos se benefician',
        'benefits-subtitle':'Todos los actores estarán interesados en tener una moneda fuerte, que se haga más fuerte cuanto más desempeñen su papel.',
        'benefits-1-title':'Investigadores',
        'benefits-1-sub':'Proyectos Financiados',
        'benefits-1-des':'La mejor manera de obtener fondos, recursos y subvenciones para entregar lo mejor posible al mundo.',
        'benefits-2-title':'Contribuyentes',
        'benefits-2-sub':'Especulación/Filantropía',
        'benefits-2-des':'Financiar toda una nueva industria en crecimiento que mejore las posibilidades de éxito. O simplemente participar en la subasta.',
        'benefits-3-title':'Usuarios finales',
        'benefits-3-sub':'Todo el mundo',
        'benefits-3-des':'Mejore la expectativa de su salud y esperanza de vida mientras forma parte del ecosistema.',
        'auction-ticker':'Subasta',
        'auction-title':'Subasta de Tokens',
        'auction-chart-1':'Asignación de Tokens',
        'auction-chart-2':'Asignación de Fondos',
        'auction-sub':'Los tokens estarán disponibles para ser reclamados después del final de cada período. ',
        'documents-ticker':'DESCARGAR DOCUMENTOS',
        'documents-title':'Lea nuestra Documentación',
        'documents-partner':'T&Cs',
        'documents-privacy':'Privacidad',
        'documents-sub':'Aquí puede encontrar toda la documentación relativa al proyecto Metha Biofund',
        'roadmap-ticker':'Nuestro Plan',
        'roadmap-title':'Hoja de Ruta',
        'roadmap-1-1':'Idea',
        'roadmap-1-2':'Primer plan',
        'roadmap-1-3':'Whitepaper v1',
        'roadmap-2-1':'Investigación Inicial',
        'roadmap-2-2':'Stress test de la idea',
        'roadmap-2-3':'Plan Estratégico',
        'roadmap-2-4':'Whitepaper v2',
        'roadmap-3-1':'Diseño',
        'roadmap-3-2':'Primera prueba conceptual',
        'roadmap-3-3':'Rediseño del proyecto',
        'roadmap-4-1':'Asesoramiento jurídico',
        'roadmap-4-2':'Primer asesoramiento jurídico',
        'roadmap-4-3':'Medidas de conformidad',
        'roadmap-5-1':'Experiencia del usuario',
        'roadmap-5-2':'Interfaces mejoradas',
        'roadmap-5-3':'Primeros conceptos de distribución de fondos',
        'roadmap-6-1':'Creación del equipo',
        'roadmap-6-2':'Pablo Maldonado se une al equipo.',
        'roadmap-6-3':'Rediseño y primera fecha límite para beta',
        'roadmap-7-1':'Prueba beta',
        'roadmap-7-2':'Prueba de resistencia de la plataforma',
        'roadmap-7-3':'Prototipo publicado y vinculado a Rinkeby blockchain con análisis en tiempo real',
        'roadmap-7-4':'Auditorías legales y de seguridad',
        'roadmap-8-1':'Lanzamiento de la Versión 1',
        'roadmap-8-2':'Contratos inteligentes migrados a la main-net de Ethereum',
        'roadmap-8-3':'Pulido de interfaz de usuario y Dapp',
        'roadmap-9-1':'Estrategia de Marketing y búsqueda de Socios',
        'roadmap-9-2':'Establecimiento de una base global de usuarios',
        'roadmap-9-3':'Intercambios de divisa.',
        'roadmap-9-4':'Auditoría y financiación de proyectos de investigación',
        'roadmap-10-1':'Nuevas características de la Dapp',
        'roadmap-10-2':'Funciones integradas para principiantes',
        'roadmap-10-3':'Integración de la plataforma Open Metha',
        'roadmap-11-1':'Ecosistema financiero',
        'roadmap-11-2':'Establecimiento del ecosistema financiero',
        'roadmap-11-3':'Estandarización del uso de la divisa METHA',
        'roadmap-sub':'La evolución de Metha Biofund de un vistazo.',
        'team-ticker':'EL EQUIPO',
        'team-title':'Equipo Ejecutivo',
        'team-sub':'No se fie de nosotros, fiése de la tecnología blockchain y su inmutabilidad.',
        'jose-victor-bio-1':'Soy un ingeniero industrial Hispano-Francés que trabaja construyendo paneles de control y avionicas desde 2015.',
        'jose-victor-bio-2':'En abril de 2017 descubrí la existencia de la tecnología blockchain, desde entonces he pensado en como mejorar cosas aplicándola. Es así cómo en 2018 empecé a construir este proyecto.',
        'maldo-bio-1':'I am an engineer and experienced cloud architect and cloud developer specialized in the Amazon Web Services ecosystem.',
        'maldo-bio-2':'My passion is to build applications to respond to problems in the most efficient way and with the highest degree of automation.',
        'maldo-bio-3':'My first contact with the blockchain was 3 years ago when I started developing automated trading bots as a hobby. Then I discovered decentralized applications and their possibilities.',
        'maldo-bio-4':'Today my goal is, through these technologies, to generate tools and bring answers to move towards decentralization in the environments where it is most needed.',
        'partners-ticker':'Frameworks',
        'partners-title':'Con la ayuda de',
        'featured-ticker':'Medios',
        'featured-title':'Destacados en',
        'faq-ticker':'FAQ',
        'faq-title':'Preguntas más frecuentes',
        'faq-sub':'A continuación hemos dado respuestas a las preguntas más comunes. Si tiene más preguntas, póngase en contacto usando el formulario de contacto.',
        'faq-metha':'Metha Biofund',
        'faq-ecosystem':'Ecosistema financiero',
        'faq-research':'Investigación',
        'faq-metha-1-q':'¿Cómo se beneficia Metha Biofund del proyecto?',
        'faq-metha-1-a':'Los ingresos de Metha Biofund serán del 10% del total de tokens de Metha, de esta manera también sumamos nuestros intereses a los de investigadores y colaboradores.',
        'faq-metha-2-q':'Una vez lanzada la plataforma, ¿cuál será el papel de Metha Biofund? ',
        'faq-metha-2-a':'Una vez establecida la plataforma, el papel de Metha Biofund será aumentar la exposición del proyecto y atraer a equipos e instituciones de investigación más talentosos dentro del ecosistema, garantizando al mismo tiempo el cumplimiento. ',
        'faq-metha-3-q':'¿Cómo puedo contribuir?',
        'faq-metha-3-a':'Puede contribuir enviando Ether a la dirección del contrato inteligente o utilizando la dapp que le proporcionamos con la extensión del navegador Metamask.',
        'faq-metha-4-q':'Estoy interesado en obtener fondos para mi investigación, ¿cómo puedo hacerlo?',
        'faq-metha-4-a':'Usted será capaz de presentar su proyecto en la plataforma abierta de metha para recabar el interés de los usuarios, o llenar una solicitud directamente a Metha Biofund. Su proyecto será auditado y luego sumido para extraer fondos del contrato inteligente. ',
        'faq-ecosystem-1-q':'¿Qué es un ecosistema financiero?',
        'faq-ecosystem-1-a':'Llamamos Ecosistema Financiero una industria que agrupa bajo el paraguas de una moneda (criptográfica) con casos de uso específicos que tienen como objetivo unificar los intereses de todos los actores involucrados, por lo que el buen comportamiento y el trabajo de un actor es beneficioso para el ecosistema.',
        'faq-ecosystem-2-q':'¿Qué es la moneda de Metha?',
        'faq-ecosystem-2-a':'La divisa Metha es un token ERC20 en el blockchain ethereum. Será la moneda para las transacciones relacionadas con patentes, derechos de patente, tratamientos, etc. resultantes de la investigación de instituciones asociadas.',
        'faq-ecosystem-3-q':'¿Cuáles son los beneficios de usar la tecnología blockchain para esto?',
        'faq-ecosystem-3-a':'La tecnología Blockchain permite un ecosistema descentralizado, donde la administración de fondos y la distribución de tokens funciona sin intermediarios, mientras que también proporciona un entorno "trustless " donde todo el mundo puede verificar cómo y cuándo se realizan las transacciones. ',
        'faq-ecosystem-4-q':'¿Por qué una subasta como método de distribución?',
        'faq-ecosystem-4-a':'Una subasta permite a la gente contribuir la cantidad que considere conveniente sin vincular ningún valor al token. Es el interés/especulación/uso de la divisa lo que afectará el precio, trayendo de esta manera una corriente constante de contribuciones dependiendo del valor de mercado libre de la moneda.',
        'faq-research-1-q':'¿Cómo se decidirá quién recibe financiación o no?',
        'faq-research-1-a':'Los proyectos se presentarán a la plataforma Metha para su auditoría. Luego, a través de un contrato inteligente, los auditores elegidos al azar revisarán el proyecto y emitirán su veredict sobre la cadena de bloques.',
        'faq-research-2-q':'¿Cuál es la pega para los proyectos que se financian?',
        'faq-research-2-a':'La única pega para ellos es demostrar su actividad por los fondos recibidos en cadena. También en caso de éxito de la investigación, se comprometerán a utilizar la divisa Metha para transacciones relacionadas con sus patentes/actividad. ',
        'faq-research-3-q':'¿Por qué se comprometieron a usar la moneda?»',
        'faq-research-3-a':'Tras la presentación de un proyecto se declarará por contrato, pero también es de su interés ya que la adopción de la moneda de Metha resulta en más contribuciones a través de la subasta.',
        'faq-research-4-q':'¿Por qué habrá fondos para becas estudiantiles?',
        'faq-research-4-a':'Creemos que es vital atraer nuevos investigadores talentosos al campo con el objetivo de prolongar la esperanza de vida. Un x% de las contribuciones a la subasta se destinará a becas para quienes deseen esta carrera. ',
        'contact-sub':'¿Alguna pregunta? Contacta con nosotros y nos pondremos en contacto contigo en breve.',
        'newsletter-title':'Mantenerse al día.',
        'newsletter-sub':'Inscríbase para recibir actualizaciones y noticias. Suscríbase a nuestro boletín y reciba información importante sobre Metha Biofund y sus socios.',
    }

}