
import React from "react";
import { Route, Switch } from "react-router-dom";
import Landing from './components/Landing';
import TokenSale from './components/TokenSale';
import Test from './components/Test';

export default (props) => {
    return (
        <Switch>
            <Route path="/" exact component={Landing} />
            <Route path="/tokensale" exact component={TokenSale} />
            <Route path="/test" exact component={Test} />
        </Switch>
    );
}