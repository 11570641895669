import { LOCALES } from '../locales';

export default {
    [LOCALES.ENGLISH]: {
        'hello': 'Hello',
        'language':'English',
        'title': 'LONGEVITY FINANCIAL ECOSYSTEM',
        'subtitle': 'Blockchain powered platform to ensure funding and help increase lifespan.',
        'menu-about': 'About',
        'menu-vision': 'Vision',
        'menu-benefits':'Benefits',
        'menu-auction':'Auction',
        'menu-roadmap':'Roadmap',
        'menu-team':'Team',
        'menu-faq':'Faqs',
        'menu-contact':'Contact',
        'button-documents':'Documentation',
        'button-auction':'Token Auction',
        'button-subscribe':'Subscribe',
        'button-contribute':'Contribute Now',
        'video-pop':'How it works?',
        'why-buy':'why buy tokens now?',
        'about-ticker':'About Metha Biofund',
        'about-title':'Our Goal is to help extend lifespan and healthspan.',
        'about-1':'Through our platform we try to create a decentralized ecosystem to solve conflict of interest in the research/big pharma industry.',
        'about-2':'We will align the interests of both contributors and research institutions by placing them under the umbrella of Metha Currency, an ERC20 token on the Ethereum blockchain.',
        'about-3':'This new currency will be distributed dayly in a process called Metha Auction and will be the currency of use for treatments, patents and cures resulting from research.',
        'vision-ticker-1':'The Problem',
        'vision-title-1':'Conflict of interest',
        'vision-1-1':'Many research institutions, specially in the field of preventive medicine and aging, are left unfunded.',
        'vision-1-2':'They fall into a category, where they are not interesting nor profitable for governments or Big Pharma to fund.',
        'vision-1-3':'This is where Metha Biofund appears, to group them into one big life extension industry with its own economic system.',
        'vision-ticker-2':'METHA CURRENCY.',
        'vision-title-2':'The Metha Auction',
        'vision-2-1':'The new metha currency will be mined through contributions in a process similar to an auction.',
        'vision-2-2':'Metha tokens will be distributed in small batches proportionally acording to daily contributions.',
        'vision-2-3':'This auction will take place while the tokens are on the free market, this will ensure daily contributions that will tend to the price per token on market.',
        'vision-ticker-3':'FINANCIAL ECOSYSTEM',
        'vision-title-3':'Transparent Smart Contracts',
        'vision-3-1':'Our decentralized ecosystem will provide with full transparent decision making and fund distribution.',
        'vision-3-2':'Popular projects among the community will be voted on chain by auditors and be funded gradually to completion.',
        'vision-3-3':'Contributors will be rewarded with their tokens, the new currency used by research institutions adhering to the program.',
        'benefits-ticker':'ACTORS INVOLVED',
        'benefits-title':'Everyone Benefits',
        'benefits-subtitle':'All the actors will be interested in having a strong currency, that becomes stronger the more they fullfill their role.',
        'benefits-1-title':'Researchers',
        'benefits-1-sub':'Funded Projects',
        'benefits-1-des':'The best way to get funds, resources and grants to deliver your best to the world.',
        'benefits-2-title':'Contributors',
        'benefits-2-sub':'Speculation/Philanthropy',
        'benefits-2-des':'Fund a whole new growing industry improving chances of success. Or just participate in the Auction.',
        'benefits-3-title':'Final Users',
        'benefits-3-sub':'Everyone',
        'benefits-3-des':'Improve your healthspan and your lifespan while being a part of the ecosystem.',
        'auction-ticker':'Auction',
        'auction-title':'Token Auction',
        'auction-chart-1':'Token Allocation',
        'auction-chart-2':'Funds Allocation',
        'auction-sub':'The tokens will be available for claiming after the end of each period.',
        'documents-ticker':'DOWNLOAD DOCUMENTS',
        'documents-title':'Read Our Documents',
        'documents-partner':'Partner Terms',
        'documents-privacy':'Privacy Policy',
        'documents-sub':'Here you can find all the documentation regarding the Metha Biofund Project',
        'roadmap-ticker':'TIMELINE',
        'roadmap-title':'Road Map',
        'roadmap-1-1':'Inception',
        'roadmap-1-2':'First scheme',
        'roadmap-1-3':'First Whitepaper',
        'roadmap-2-1':'Research',
        'roadmap-2-2':'Stress testing the idea',
        'roadmap-2-3':'Strategic Plan',
        'roadmap-2-4':'White paper completion',
        'roadmap-3-1':'Design',
        'roadmap-3-2':'First Proof of concept',
        'roadmap-3-3':'Redesign of the project',
        'roadmap-4-1':'Legal Advising',
        'roadmap-4-2':'First legal advising',
        'roadmap-4-3':'Compliance measures',
        'roadmap-5-1':'User Experience',
        'roadmap-5-2':'Improving interfaces',
        'roadmap-5-3':'First concepts of fund distribution',
        'roadmap-6-1':'Team Formation',
        'roadmap-6-2':'Pablo Maldonado joins',
        'roadmap-6-3':'Redesing and first deadline for beta',
        'roadmap-7-1':'Beta Test',
        'roadmap-7-2':'Stress test of the platform',
        'roadmap-7-3':'Prototype published and linked to Rinkeby blockchain withreal-time scanning',
        'roadmap-7-4':'Security & Legal Audits',
        'roadmap-8-1':'Platform V1 Launch',
        'roadmap-8-2':'Smart contracts migrated to Ethereum Blockchain',
        'roadmap-8-3':'Dapp and user interface polish',
        'roadmap-9-1':'Marketing & Partner lookup',
        'roadmap-9-2':'Establishing global user base<',
        'roadmap-9-3':'Exchange listings',
        'roadmap-9-4':'Research Projects audit & funding',
        'roadmap-10-1':'New Dapp features',
        'roadmap-10-2':'Novice friendly features integrated',
        'roadmap-10-3':'Open Metha Platform integration',
        'roadmap-11-1':'Financial Ecosystem',
        'roadmap-11-2':'Financial ecosystem establishing',
        'roadmap-11-3':'Standarizing the use of the METHA currency',
        'roadmap-sub':'The evolution of Metha Biofund at a glance.',
        'team-ticker':'MEET THE TEAM',
        'team-title':'Executive team',
        'team-sub':'We are a couple of nobodies that get things done. Do not trust us, trust blockchain technology and its immutability.',
        'jose-victor-bio-1':'I\'m a French-Spanish industrial engineer that has been building cockpit control panels for companies like Airbus or Indonesian aerospace since 2015',
        'jose-victor-bio-2':'In April 2017 i got exposure to blockchain technology and shortly after started thinking of ways to improve things thanks to it. This is how in 2018 I started building this project.',
        'maldo-bio-1':'I am an engineer and experienced cloud architect and cloud developer specialized in the Amazon Web Services ecosystem.',
        'maldo-bio-2':'My passion is to build applications to respond to problems in the most efficient way and with the highest degree of automation.',
        'maldo-bio-3':'My first contact with the blockchain was 3 years ago when I started developing automated trading bots as a hobby. Then I discovered decentralized applications and their possibilities.',
        'maldo-bio-4':'Today my goal is, through these technologies, to generate tools and bring answers to move towards decentralization in the environments where it is most needed.',
        'partners-ticker':'Frameworks',
        'partners-title':'Built with',
        'featured-ticker':'Media',
        'featured-title':'Featured in',
        'faq-ticker':'FAQ',
        'faq-title':'Frequently asked questions',
        'faq-sub':'Below we’ve provided answers to the most usual questions. If you have any other questions, please get in touch using the contact form below.',
        'faq-metha':'Metha Biofund',
        'faq-ecosystem':'Financial Ecosystem',
        'faq-research':'Research Institutions',
        'faq-metha-1-q':'How does Metha Biofund Profit from the project?',
        'faq-metha-1-a':'Metha Biofund revenue will be 10% of total Metha tokens, this way we also add our interests to those of researchers and contributors.',
        'faq-metha-2-q':'Once the platform is launched, what will be the role of Metha Biofund?',
        'faq-metha-2-a':'Once the platform is established, Metha Biofund role will be to increase exposure of the project and attract more talented research teams and institutions inside the ecosystem while also ensuring compliance.',
        'faq-metha-3-q':'How can I Contribute?',
        'faq-metha-3-a':'You can contribute either by sending Ether to the smart contract address (donation) or by using the dapp we provided with Metamask browser extension (tokens will be generated).',
        'faq-metha-4-q':'I am interested in getting funds for my research, how can i do it?',
        'faq-metha-4-a':'You will be able to present your project in the open metha platform to gather interest from users, or fill an application directly to Metha Biofund. Your project will be audited and then submited to extract funds from the smart contract.',
        'faq-ecosystem-1-q':'What is a Financial Ecosystem?',
        'faq-ecosystem-1-a':'We call Financial Ecosystem an industry that groups under the umbrella of a (crypto)currency with specific use cases that aim at unifying interests of all actors involved, so the good behaviour and work of an actor is beneficial to the ecosystem.',
        'faq-ecosystem-2-q':'What is Metha currency?',
        'faq-ecosystem-2-a':'Metha Currency is a ERC20 Token on the ethereum blockchain. It will be the currency for transactions involving patents, patent rights, treatments etc... resulting from research from partner institutions.',
        'faq-ecosystem-3-q':'What are the benefits of using blockchain technology for this?',
        'faq-ecosystem-3-a':'Blockchain technology allows for a decentralized ecosystem, where the fund managing and token distribution works without middleman while also providing a trustless environement where enyone can check how and when transactions are made.',
        'faq-ecosystem-4-q':'Why an auction as method of distribution?',
        'faq-ecosystem-4-a':'An auction allows people to contribute the amount they see fit without tying any value to the token. It is the interest/speculation/use of the token that will affect the price, bringing this way a constant stream of contributions depending on the free market value of the currency.',
        'faq-research-1-q':'How will it be decided who is funded or not?',
        'faq-research-1-a':'Projects will be submitted to Metha Platform for auditing. Then through a smart contract random chosen auditors will review the project and cast their veredict on the blockchain.',
        'faq-research-2-q':'What is the catch for projects being funded?',
        'faq-research-2-a':'The only catch for them is to show proof of their activity for the funds recieved on chain. Also in the event of successfull research they will commit to using Metha Currency for transactions related to their patents/activity.',
        'faq-research-3-q':'Why would they commit to use the currency?',
        'faq-research-3-a':'Upon submit of a project it will be stated by contract, but also it is in their interest since adoption of Metha currency results in more contributions through the Auction.',
        'faq-research-4-q':'Why will there be funds for student grants?',
        'faq-research-4-a':'We believe that it is vital to attract new talented researchers into the field in the goal to extend lifespan. A x% of Auction contributions will head towards grants for those chosing this career.',
        'contact-sub':'Any question? Reach out to us and we’ll get back to you shortly.',
        'newsletter-title':'Don\'t miss out, Stay updated',
        'newsletter-sub':'Sign up for updates and news. Subscribe to our newsletter and receive important information about Metha Biofund and its partners.',
    }

}