import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Typography
} from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    marginRight: 0,
    marginTop: 0
  },
  overview: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start'
    }
  },
  product: {
    display: 'flex',
    alignItems: 'center'
  },
  productImage: {
    marginRight: theme.spacing(1),
    height: 48,
    width: 48
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  notice: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  flex: {
    display: "flex"
  },
  marginLeft: {
    margin: "auto",
    marginLeft: theme.spacing(2)
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    width: "33%",
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  overline: {
    marginTop: theme.spacing(1)
  },
  bar: {
    padding: theme.spacing(0, 3, 3, 3),
  },
  slide: {
    width: "100%"
  },
  margin: {
    height: theme.spacing(3),
  },
}));

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const Timer = props => {
  const { className } = props;

  const classes = useStyles();



  const [time, setTime] = useState(moment(new Date()).format('DD/MM/YYYY  HH:mm:ss'));

  useInterval(() => {
    setTime(moment(new Date()).format('DD/MM/YYYY  HH:mm:ss'));
  }, 1000);

  return (
    <Typography
      variant="overline">
      {time}
    </Typography>
  );
};

Timer.propTypes = {
  className: PropTypes.string
};

export default Timer;
