import React, { useEffect } from 'react';
import useScript from '../../hooks/useScript';
import { withContext } from '../../context';

export default withContext((props) => {
    useScript("assets/js/scripts.js");
    return (
        <div className="nk-wrap">
            <header className="nk-header page-header is-sticky is-shrink is-transparent is-light" id="header">
                {/* Header @s */}
                <div className="header-main" >
                    <div className="header-container container">
                        <div className="header-wrap">
                            {/* Logo @s */}
                            <div className="header-logo logo " data-animate="fadeInDown" data-delay=".65">
                                <a href="./" className="logo-link">
                                    <img className="logo-dark" src="images/logo.png" srcSet="images/logo2x.png 2x" alt="logo" />
                                    <img className="logo-light" src="images/logo.png" srcSet="images/logo2x.png 2x" alt="logo" />
                                </a>
                            </div>
                            {/* Menu Toogle @s */}
                            <div className="header-nav-toggle">
                                <a href="#" className="navbar-toggle" data-menu-toggle="header-menu">
                                    <div className="toggle-line">
                                        <span />
                                    </div>
                                </a>
                            </div>
                            {/* Menu @s */}
                            <div className="header-navbar header-navbar-s1">
                                <nav className="header-menu" id="header-menu">
                                    <ul className="menu " data-animate="fadeInDown" data-delay=".75">
                                        <li className="menu-item"><a className="menu-link nav-link" href="#about">About</a></li>
                                        <li className="menu-item"><a className="menu-link nav-link" href="#why">Why</a></li>
                                        {/**/}
                                        <li className="menu-item"><a className="menu-link nav-link" href="#benifits">Benifits</a>
                                        </li>
                                        <li className="menu-item"><a className="menu-link nav-link" href="#token">Token Sale</a>
                                        </li>
                                        <li className="menu-item"><a className="menu-link nav-link" href="#roadmap">Roadmap</a></li>
                                        <li className="menu-item"><a className="menu-link nav-link" href="#team">Team</a></li>
                                        <li className="menu-item"><a className="menu-link nav-link" href="#faqs">Faqs</a></li>
                                        <li className="menu-item"><a className="menu-link nav-link" href="#contact">Contact</a></li>
                                    </ul>
                                    <ul className="menu-btns menu-btns-s3 align-items-center " data-animate="fadeInDown" data-delay=".85">
                                        <li className="language-switcher language-switcher-s1 toggle-wrap">
                                            <a className="toggle-tigger" href="#">English</a>
                                            <ul className="toggle-class toggle-drop toggle-drop-left drop-list drop-list-sm">
                                                <li><a href="#">French</a></li>
                                                <li><a href="#">Chinese</a></li>
                                                <li><a href="#">Hindi</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="#" data-toggle="modal" data-target="#register-popup" className="btn btn-md btn-primary btn-outline" onClick={() => console.log("e")}><span>Home</span></a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
})
