import React from 'react';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from "prop-types";
import {
    ContractData,
    ContractForm,
} from "@drizzle/react-components";
import { withDrizzle, provideDrizzle } from '../../withDrizzle';
import { Header } from "../Layout";

class Tokensalemeta extends React.Component {
    // For convenience
    constructor(props, context) {
        super(props);
        this.state = {
            paramKeysToSync: {},
            syncParams: {}
        }
        this.drizzle = context.drizzle;
        this.contracts = context.drizzle.contracts;
    }

    async componentDidMount() {

        this._addParamsToSync(["get_current_period", "get_start_time_of_next_period", "get_wei_raised"], "MethaCrowdsale");

        /*
                const contributor_data_per_period = await MethaCrowdsale
                    .methods
                    .get_contributor_data_per_period(current_period, accounts[0])
                    .call();
        
                const wei_contribution = contributor_data_per_period[2];
        
                const token_balance = await MethaTokenInstance
                    .methods
                    .balanceOf(accounts[0])
                    .call()
        
                const wei_raised_per_period = await MethaCrowdsale
                    .methods
                    .wei_raised_per_period(current_period)
                    .call();
        
                const start_time_of_next_period = await MethaCrowdsale
                    .methods
                    .start_time_of_next_period()
                    .call();
        
                const wei_raised = await MethaCrowdsale
                    .methods
                    .wei_raised()
                    .call()
                const wallets_authorized_count = await MethaCrowdsale
                    .methods
                    .get_wallets_authorized_count()
                    .call() */
    }


    _addParamsToSync = (params, contract) => {
        const { paramKeysToSync, syncParams } = this.state;

        var newParamKeysToSync = { ...paramKeysToSync };
        var newSyncParams = { ...syncParams };

        //Init contract obj if doesn't exist
        if (typeof newParamKeysToSync[contract] !== "object") newParamKeysToSync[contract] = {};
        if (typeof newSyncParams[contract] !== "object") newSyncParams[contract] = {};

        params.forEach(param => {

            let key = this.contracts[contract].methods[param].cacheCall();
            newParamKeysToSync[contract][param] = key;
            newSyncParams[contract][param] = null;
        });

        return this.setState({
            paramKeysToSync: newParamKeysToSync,
            syncParams: newSyncParams
        });
    }

    _getSyncedParams = (prevProps) => {
        const { paramKeysToSync, syncParams } = this.state;
        const contractsToSync = Object.keys(paramKeysToSync);
        var newSyncParams = { ...syncParams };
        var updateSyncedParams = false;
        contractsToSync.forEach(contract => {
            if (prevProps[contract] !== this.props[contract]) {
                updateSyncedParams = true;
                var contractParamKeysToSync = Object.keys(paramKeysToSync[contract]);
                contractParamKeysToSync.forEach(paramKeyName => {
                    if (
                        paramKeysToSync[contract][paramKeyName] in
                        this.props.MethaCrowdsale[paramKeyName]
                    ) newSyncParams[contract][paramKeyName] = this.props.MethaCrowdsale[paramKeyName][paramKeysToSync[contract][paramKeyName]].value;
                });
            }
        });
        if (updateSyncedParams) return this.setState({ syncParams: newSyncParams });
    }

    componentDidUpdate(prevProps, prevState) {
        this._getSyncedParams(prevProps);
    }

    render() {
        const { syncParams } = this.state;
        const { accounts } = this.props;
        return (
            <div>
                
                <ToastContainer />
                
                {syncParams["MethaCrowdsale"] &&
                    <div>
                        < div >
                            Current Period: {syncParams["MethaCrowdsale"]["get_current_period"]}
                        </div>
                        {/*  < div >
                            {new Date(syncParams["MethaCrowdsale"]["start_time_of_next_period"]).toDateString()}
                        </div> */}
                        < div >
                            Total raised: {syncParams["MethaCrowdsale"]["get_wei_raised"] / Math.pow(10, 18)} ETH
                        </div>
                    </div>
                }

                <ContractForm contract="MethaCrowdsale" method="buy" sendArgs={{ value: Math.pow(10, 17), from: accounts[0] }} />
                {/* <ContractData contract="MethaCrowdsale" method="wei_raised" />
                <ContractData contract="MethaCrowdsale" method="start_time_of_next_period" /> */}
            </div >
        )
    }
}



Tokensalemeta.contextTypes = {
    drizzle: PropTypes.object,
};

export default provideDrizzle(withDrizzle(Tokensalemeta));