import React, { useState, useEffect } from 'react';

export default (props) => {

    const calculateTimeLeft = () => {
        const difference = props.remainingPeriodTime * 1000 - timePassed;

        let timeLeft = {};

        if (difference >= 0) {
            timeLeft = {
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        } else {
            timeLeft = {
                hours: 0,
                minutes: 0,
                seconds: 0
            };
        }
        return timeLeft;
    };

    const [timePassed, setTimePassed] = useState(0);
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        var interval = setInterval(() => {
            setTimePassed(timePassed + 1000);
            var timeLeft = calculateTimeLeft();
            if (timeLeft.hours == 0 && timeLeft.minutes == 0 && timeLeft.seconds == 0) {
                setTimePassed(0);
                props.onRefreshData();
            }
            setTimeLeft(timeLeft);
        }, 1000);
        return function () {
            clearInterval(interval);
        }
    });


    const timerComponents = [];

    const formatNumber = (number) => {
        var strNumber = String(number);
        return strNumber.length < 2 ? "0" + strNumber : strNumber;
    }

    Object.keys(timeLeft).forEach((interval, i) => {
        timerComponents.push(
            <div key={"interval" + i} className="countdown-item" style={{ width: 100 }}>
                <div className="countdown-time" >
                    <span>
                        {formatNumber(timeLeft[interval])}
                    </span>
                </div>
                <div className="countdown-text">
                    {interval}
                </div>
            </div>
        );
    });

    return (
        <div style={{ margin: "auto", display: "flex", overflow: "hidden", marginTop: 6 }}>
            {timerComponents}
        </div>
    );
}
