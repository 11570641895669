import React, { useState, useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/styles";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Typography,
  Grid,
} from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import Label from "components/Label";
import { colors } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import { orderBy, round } from "lodash";
import { withContext } from "context";
import Timer from "./Timer";
import { weiToEth } from "utils/weiUtils";

const useStyles = makeStyles((theme) => ({
  root: {},
  action: {
    marginRight: 0,
    marginTop: 0,
  },
  overview: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
      alignItems: "flex-start",
    },
  },
  product: {
    display: "flex",
    alignItems: "center",
  },
  productImage: {
    marginRight: theme.spacing(1),
    height: 48,
    width: 48,
  },
  details: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  notice: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  flex: {
    display: "flex",
  },
  marginLeft: {
    margin: "auto",
    marginLeft: theme.spacing(2),
  },
  item: {
    padding: theme.spacing(3),
    textAlign: "center",
    width: "33%",
    [theme.breakpoints.up("md")]: {
      "&:not(:last-of-type)": {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&:not(:last-of-type)": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  overline: {
    marginTop: theme.spacing(1),
  },
  bar: {
    padding: theme.spacing(0, 3),
  },
  slide: {
    width: "100%",
  },
  margin: {
    height: theme.spacing(3),
  },
  cardContent: {
    paddingBottom: "0px !important",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 4),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 4),
    },
  },
  sliderMargin: {
    [theme.breakpoints.up("sm")]: {
      marginTop: -42,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: -44,
    },
  },
}));

const CustomSlider = withStyles({
  root: {
    color: "#16a1ff",
    height: 8,
  },
  thumb: {
    height: 24,
    zIndex: 800,
    width: 24,
    backgroundColor: "rgba(255,255,255,.92)",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
    color: colors.indigo[400],
    zIndex: 800,
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const CustomSliderHidden = withStyles({
  root: {
    pointerEvents: "none",
    height: 8,
  },
  thumb: {
    color: "unset",
    pointerEvents: "all",
    height: 24,
    width: 24 /* 
    backgroundColor: '#fff', */,
    border: "1px solid rgba(193, 206, 241, 0.5)",
    margin: "auto",
    marginTop: -8,
    marginLeft: -1,
    borderRadius: 2,
    width: 2,
    height: 36,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    marginLeft: -12,
  },
  track: {
    pointerEvents: "none",
    height: 8,
    borderRadius: 4,
    visibility: "hidden",
  },
  rail: {
    pointerEvents: "none",
    height: 8,
    borderRadius: 4,
    visibility: "hidden",
  },
  mark: {
    pointerEvents: "all",
    visibility: "hidden",
  },
  markLabel: {
    pointerEvents: "none",
    marginTop: 15,
    fontFamily: "unset !important",
    /* visibility: "hidden" */
  },
  markLabelActive: {
    /* visibility: "inherit" */
  },
})(Slider);

const SaleProgress = (props) => {
  const {
    className,
    periodRaised,
    getTokensaleStats: tokenSaleStats,
    ...rest
  } = props;

  const classes = useStyles();

  console.log(tokenSaleStats);

  const marks = [
    {
      value: !tokenSaleStats ? 0 : weiToEth(tokenSaleStats["2"]),
      label: `BEST DAY`,
    },
    {
      value: !tokenSaleStats ? 0 : weiToEth(tokenSaleStats["1"]),
      label: `AVERAGE DAY`,
    },
    {
      value: !tokenSaleStats ? 0 : weiToEth(tokenSaleStats["0"]),
      label: `WORST DAY`,
    },
  ];

  const getSliderValues = () => {
    var _marks = [...marks];
    _marks.push({
      value: periodRaised,
      label: "RAISED",
    });
    _marks = orderBy(_marks, ["value"], ["asc"]);
    var realMax = _marks[3].value;
    var realMin = _marks[0].value;
    var realDiff = realMax - realMin;

    var coef = 0.15;
    var min = round(realMin - realDiff * coef, 6);
    var max = round(realMax + realDiff * coef, 6);

    _marks = _marks.filter((el) => el.label !== "RAISED");
    console.log(_marks.length);

    for (var i = _marks.length - 1; i >= 0; i--) {
      if (i - 1 >= 0) {
        var a = _marks[i].value;
        var b = _marks[i - 1].value;
        if (a - b <= realDiff * 0.1) {
          _marks[i - 1].label = `${_marks[i - 1].label}, ${_marks[i].label}`;
          _marks[i].label = "";
        }
      }
    }

    return {
      min,
      max,
      marks: _marks,
    };
  };

  var sliderValues = getSliderValues();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
      style={{ backgroundColor: "rgba(255,255,255,0)", boxShadow: "unset" }}
    >
      <CardContent className={classes.cardContent}>
        <div className={classes.bar}>
          <div className={classes.slide}>
            <div style={{ width: "100%" }}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <div style={{ display: "flex", marginRight: "auto" }}>
                    <Typography
                      variant="body1"
                      style={{ fontSize: 12, whiteSpace: "nowrap" }}
                    >
                      PERIOD RAISED -
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{
                        marginLeft: 2,
                        fontSize: 12,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {periodRaised} ETH
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{ display: "flex" }}>
                    <Typography
                      variant="body1"
                      style={{
                        marginLeft: props.width == "xs" ? "unset" : "auto",
                        fontSize: 12,
                        whiteSpace: "nowrap",
                      }}
                    >
                      DISTRIBUTION -
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{
                        marginLeft: 2,
                        fontSize: 12,
                        whiteSpace: "nowrap",
                      }}
                    >
                      10,000
                    </Typography>
                    <img
                      style={{
                        width: 20,
                        height: 20,
                        marginLeft: 8,
                        marginBottom: 4,
                      }}
                      src={"images/favicon.png"}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginTop: 16 }}>
              <div>
                <CustomSlider
                  value={periodRaised}
                  step={10}
                  min={sliderValues.min}
                  max={sliderValues.max}
                  valueLabelDisplay="auto"
                />
              </div>
              <div className={classes.sliderMargin}>
                <CustomSliderHidden
                  value={sliderValues.marks.map((el) => el.value)}
                  step={10}
                  track={false}
                  marks={sliderValues.marks}
                  min={sliderValues.min}
                  max={sliderValues.max}
                  valueLabelDisplay="auto"
                />
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

SaleProgress.propTypes = {
  className: PropTypes.string,
};

export default withWidth()(withContext(SaleProgress));
