import { LOCALES } from '../locales';

export default {
    [LOCALES.FRENCH]: {
        'hello': 'Hello',
        'language':'French',
        'title': 'ECOSYSTÈME FINANCIER DÉCENTRALISÉ',
        'subtitle': 'Plateforme alimentée Blockchain pour assurer le financement et aider à augmenter la durée de vie.',
        'menu-about': 'About',
        'menu-vision': 'Vision',
        'menu-benefits':'Benefits',
        'menu-auction':'Auction',
        'menu-roadmap':'Roadmap',
        'menu-team':'Team',
        'menu-faq':'Faq',
        'menu-contact':'Contact',
        'button-documents':'Documentation',
        'button-auction':'Token Auction',
        'button-subscribe':'m\'inscrire',
        'button-contribute':'Contribuer',
        'video-pop':'Comment ça marche?',
        'why-buy':'Pourquoi acheter des tokens?',
        'about-ticker':'A propos de Metha Biofund',
        'about-title':'Notre objectif est de aider à prolonger la durée de vie et la qualité de vie.',
        'about-1':'Grâce à notre plateforme, nous essayons de créer un écosystème décentralisé pour résoudre les conflits d\'intérêts dans le secteur de la recherche et de la grande industrie pharmaceutique.',
        'about-2':'Nous alignerons les intérêts des contributeurs et des institutions de recherche en les plaçant sous l\'égide de Metha Currency, un jeton ERC20 sur la blockchain Ethereum.',
        'about-3':'Cette nouvelle monnaie sera distribuée quotidiennement dans le cadre d\'un processus appelé Metha Auction et sera la monnaie d\'utilisation pour les traitements, les brevets et les exploitations résultant de la recherche.',
        'vision-ticker-1':'Le Problème',
        'vision-title-1':'Conflit d\'intérêts',
        'vision-1-1':'De nombreuses institutions de recherche, en particulier dans le domaine de la médecine préventive et du vieillissement, ne sont pas financées.',
        'vision-1-2':'Elles rentrent dans une catégorie où elles ne sont ni intéressantes ni rentables pour les gouvernements ou les grands pharmas à financer.',
        'vision-1-3':'C\'est là que Metha Biofund apparaît, pour les regrouper en une seule grande industrie d\'extension de la durée de vie avec son propre système économique.',
        'vision-ticker-2':'METHA CURRENCY.',
        'vision-title-2':'La Metha Auction',
        'vision-2-1':'La nouvelle monnaie "Métha" sera explosée au moyen de contributions dans le cadre d\'un processus similaire à une vente aux enchères.',
        'vision-2-2':'Les jetons Metha seront distribués en petits lots proportionnellement aux contributions quotidiennes.',
        'vision-2-3':'Cette vente aux enchères aura lieu pendant que les jetons sont sur le marché libre, cela assurera des contributions quotidiennes qui tendront au prix par jeton sur le marché.',
        'vision-ticker-3':'ECOSYSTEME FINANCIER',
        'vision-title-3':'Contrats intelligents transparents',
        'vision-3-1':'Notre écosystème décentralisé assurera une prise de décision transparente et une répartition des fonds.',
        'vision-3-2':'Les projets populaires au sein de la communauté seront votés en chaîne par les auditeurs et seront financés progressivement jusqu\'à leur achèvement.',
        'vision-3-3':'Les contributeurs seront récompensés avec leurs jetons, la nouvelle monnaie utilisée par les établissements de recherche adhérant au programme.',
        'benefits-ticker':'ACTEURS INCIPÉS',
        'benefits-title':'Avantages pour tous',
        'benefits-subtitle':'Tous les acteurs seront intéressés à avoir une monnaie forte, qui deviendra plus forte plus ils remplissent leur rôle.',
        'benefits-1-title':'Chercheurs',
        'benefits-1-sub':'Projets financés',
        'benefits-1-des':'La meilleure façon d\'obtenir des fonds, des ressources et des subventions pour offrir le meilleur de vous au monde.',
        'benefits-2-title':'Contributeurs',
        'benefits-2-sub':'Spéculation/philanthropie',
        'benefits-2-des':'Financer une toute nouvelle industrie en pleine croissance améliorant les chances de succès. Ou simplement participer à la vente aux enchères.',
        'benefits-3-title':'Utilisateurs finaux',
        'benefits-3-sub':'Tout le monde',
        'benefits-3-des':'Améliorez votre santé et votre durée de vie tout en faisant partie de l\'écosystème.',
        'auction-ticker':'Enchère',
        'auction-title':'Token Auction',
        'auction-chart-1':'Allocation de METHA',
        'auction-chart-2':'Allocation de fonds',
        'auction-sub':'Les jetons seront disponibles pour réclamer après la fin de chaque période.',
        'documents-ticker':'TÉLÉCHARGER DOCUMENTS',
        'documents-title':'Lisez nos documents',
        'documents-partner':'Terms partenaires',
        'documents-privacy':'Politique de Confidentialité',
        'documents-sub':'Ici vous trouverez toute la documentation concernant le projet Metha Biofund',
        'roadmap-ticker':'TIMELINE',
        'roadmap-title':'Ligne du Temps',
        'roadmap-1-1':'Inception',
        'roadmap-1-2':'Premier schéma',
        'roadmap-1-3':'Whitepaper v0.1',
        'roadmap-2-1':'Recherche',
        'roadmap-2-2':'stress-test de l\'idée',
        'roadmap-2-3':'Plan stratégique',
        'roadmap-2-4':'Whitepaper v0.2',
        'roadmap-3-1':'Conception',
        'roadmap-3-2':'Première preuve de concept',
        'roadmap-3-3':'Refonte du projet',
        'roadmap-4-1':'Conseils juridiques',
        'roadmap-4-2':'Premier conseil juridique',
        'roadmap-4-3':'Mesures de conformité',
        'roadmap-5-1':'Expérience de l\'utilisateur',
        'roadmap-5-2':'Interfaces améliorées',
        'roadmap-5-3':'Premiers concepts de distribution des fonds',
        'roadmap-6-1':'Team building',
        'roadmap-6-2':'Pablo Maldonado joint le projet',
        'roadmap-6-3':'Refonte et premier délai pour la bêta',
        'roadmap-7-1':'Beta Test',
        'roadmap-7-2':'Test de résistance de la plate-forme',
        'roadmap-7-3':'Prototype publié et lié à la blockchain Rinkeby avec analyse en temps réel',
        'roadmap-7-4':'Audits juridiques et de sécurité',
        'roadmap-8-1':'Lancement de la V1',
        'roadmap-8-2':'Contrats intelligents migrés sur la blockchain d\'Ethereum',
        'roadmap-8-3':'Polissage de l\interface utilisateur et Dapp',
        'roadmap-9-1':'Marketing & Recherche de partenaires',
        'roadmap-9-2':'Établir une base mondiale d\'utilisateurs',
        'roadmap-9-3':'Exchange listings',
        'roadmap-9-4':'Audit et financement des projets de recherche',
        'roadmap-10-1':'Nouvelles fonctionnalités Dapp',
        'roadmap-10-2':'Fonctions intégrées pour amicales pour les débutants',
        'roadmap-10-3':'Integration Open Metha Platform',
        'roadmap-11-1':'Écosystème financier',
        'roadmap-11-2':'Mise en place d\'un écosystème financier',
        'roadmap-11-3':' Standarization de l\'utilisation de la monnaie METHA',
        'roadmap-sub':'L\'évolution de Metha Biofund en un coup d\'œil.',
        'team-ticker':'Rencontrez l\'equipe',
        'team-title':'Équipe exécutive',
        'team-sub':'Méfiez vous de nous, mais faites confiance a l\'inmutabilité de la Technologie Blockchain',
        'jose-victor-bio-1':'Je suis un ingenieur industriel Franco-Espagnol qui travaille dans la construction d\'instruments de bord pour des compagnies telles que Airbus ou Indonesian Aerospace depuis 2015. ',
        'jose-victor-bio-2':'En Avril 2017 j\'ai connu la technologie blockchain et depuis, je pense a comment l\'utiliser pour améliorer des systèmes. C\'est comme ça que en 2018 j\'ai commencé a construire çe projet',
        'maldo-bio-1':'I am an engineer and experienced cloud architect and cloud developer specialized in the Amazon Web Services ecosystem.',
        'maldo-bio-2':'My passion is to build applications to respond to problems in the most efficient way and with the highest degree of automation.',
        'maldo-bio-3':'My first contact with the blockchain was 3 years ago when I started developing automated trading bots as a hobby. Then I discovered decentralized applications and their possibilities.',
        'maldo-bio-4':'Today my goal is, through these technologies, to generate tools and bring answers to move towards decentralization in the environments where it is most needed.',
        'partners-ticker':'Frameworks',
        'partners-title':'Construit avec',
        'featured-ticker':'Media',
        'featured-title':'Visibles sur',
        'faq-ticker':'FAQ',
        'faq-title':'Foire aux questions',
        'faq-sub':'Ci-dessous, nous avons donné des réponses aux questions les plus courantes. Si vous avez d\'autres questions, veuillez utiliser le formulaire de contact ci-dessous.',
        'faq-metha':'Metha Biofund',
        'faq-ecosystem':'Ecosystème financier',
        'faq-research':'Institutions de recherche',
        'faq-metha-1-q':'Comment Metha Biofund profite-t-il du projet ?',
        'faq-metha-1-a':'Le revenu de Metha Biofund sera de 10% du total des jetons Metha, de cette façon, nous ajoutons également nos intérêts à ceux des chercheurs et des contributeurs.',
        'faq-metha-2-q':'Une fois la plateforme lancée, quel sera le rôle de Metha Biofund ?',
        'faq-metha-2-a':'Une fois la plateforme établie, le rôle de Metha Biofund sera d\'accroître l\'exposition du projet et d\'attirer des équipes de recherche et des institutions plus talentueuses à l\'intérieur de l\'écosystème tout en assurant la conformité.',
        'faq-metha-3-q':'Comment puis-je contribuer ?',
        'faq-metha-3-a':'Vous pouvez contribuer soit en envoyant Ether à l\'adresse du contrat intelligent, soit en utilisant le dapp fourni avec l\'extension de navigateur Metamask.',
        'faq-metha-4-q':'Je suis intéressé à obtenir des fonds pour ma recherche, comment puis-je le faire ?',
        'faq-metha-4-a':'Vous serez en mesure de présenter votre projet sur la plateforme open metha pour recueillir l\'intérêt des utilisateurs, ou de remplir une demande directement à Metha Biofund. Votre projet sera audité puis soumis pour extraire des fonds du contrat intelligent.',
        'faq-ecosystem-1-q':'Qu\'est-ce qu\'un écosystème financier ?',
        'faq-ecosystem-1-a':'Nous appelons écosystème financier une industrie qui se regroupe sous l\'égide d\'une monnaie (crypto) avec des cas d\'utilisation spécifiques qui visent à unifier les intérêts de tous les acteurs impliqués, de sorte que le bon comportement et le travail d\'un acteur sont bénéfiques pour l\'écosystème.',
        'faq-ecosystem-2-q':'Qu\'est-ce que la monnaie Metha ?',
        'faq-ecosystem-2-a':'La monnaie Metha est un jeton ERC20 sur la blockchain ethereum. Ce sera la monnaie pour les transactions impliquant des brevets, des droits de brevet, des traitements, etc. résultant de recherches menées par des institutions partenaires.',
        'faq-ecosystem-3-q':'Quels sont les avantages de l\'utilisation de la technologie blockchain pour cela ?',
        'faq-ecosystem-3-a':'La technologie Blockchain permet un écosystème décentralisé, où la gestion de fonds et la distribution de jetons fonctionnent sans intermédiaire tout en fournissant un environnement sans confiance où enyone peut vérifier comment et quand les transactions sont effectuées.',
        'faq-ecosystem-4-q':'Pourquoi une vente aux enchères comme méthode de distribution ?',
        'faq-ecosystem-4-a':'Une vente aux enchères permet aux gens de contribuer le montant qu\'ils jugent approprié sans lier aucune valeur au jeton. C\'est l\'intérêt/spéculation/utilisation du jeton qui affectera le prix, apportant ainsi un flux constant de contributions en fonction de la valeur de marché libre de la monnaie.',
        'faq-research-1-q':'How will it be decided who is funded or not?',
        'faq-research-1-a':'Projects will be submitted to Metha Platform for auditing. Then through a smart contract random chosen auditors will review the project and cast their veredict on the blockchain.',
        'faq-research-2-q':'What is the catch for projects being funded?',
        'faq-research-2-a':'La seule prise pour eux est de prouver leur activité pour les fonds reçus sur la chaîne. Également en cas de recherche réussie, ils s\'engageront à utiliser Metha Monnaie pour les transactions liées à leurs brevets/activités.',
        'faq-research-3-q':'Pourquoi s\'engageraient-ils à utiliser la monnaie ?',
        'faq-research-3-a':'Lors de la soumission d\'un projet, il sera déclaré par contrat, mais il est également dans leur intérêt puisque l\'adoption de la monnaie Metha entraîne plus de contributions par le biais de l\'Enchère.',
        'faq-research-4-q':'Pourquoi y aura-t-il des fonds pour les bourses d\'études ?',
        'faq-research-4-a':'Nous pensons qu\'il est essentiel d\'attirer de nouveaux chercheurs talentueux dans le domaine afin de prolonger leur durée de vie. Un x% des contributions aux enchères se dirigera vers des subventions pour ceux qui choisissent cette carrière.',
        'contact-sub':'Une question ? Contactez-nous et nous vous répondrons au plus vite.',
        'newsletter-title':'Ne manquez pas, Restez mis à jour',
        'newsletter-sub':'Inscrivez-vous pour les mises à jour et les nouvelles. Abonnez-vous à notre newsletter et recevez des informations importantes sur Metha Biofund et ses partenaires.',
    }

}