import React from 'react';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from "prop-types";
import {
    ContractData,
    ContractForm,
} from "@drizzle/react-components";
import { withDrizzle, provideDrizzle } from '../../withDrizzle';
import { Header } from "../Layout";
import TokenSaleNoMeta from '../TokenSaleNoMeta';
import TokenSaleMeta from '../TokenSaleMeta';

class Tokensale extends React.Component {
    // For convenience
    constructor(props, context) {
        super(props);
        this.state = {
        }

    }

    render() {

        return (
            <div>
                <Header />
                <div style={{ height: 100 }}>

                </div>
                <div>
                    <TokenSaleMeta />
                </div >
                <div style={{ height: 100 }}>
                    <TokenSaleNoMeta />
                </div>
            </div>
        )
    }
}



Tokensale.contextTypes = {
    drizzle: PropTypes.object,
};

export default Tokensale;