import React from 'react';
import { DrizzleProvider, drizzleConnect } from "@drizzle/react-plugin";
import { LoadingContainer } from "@drizzle/react-components";
import drizzleOptions from "./drizzleOptions";
import store from './middleware'

//Mappings
const mapStateToProps = state => {
    return {
        accounts: state.accounts,
        MethaToken: state.contracts.MethaToken,
        MethaCrowdsale: state.contracts.MethaCrowdsale,
        drizzleStatus: state.drizzleStatus
    }
}

const withLoader = Component => props => <LoadingContainer><Component {...props} /></LoadingContainer>

const withDrizzle = Component => drizzleConnect(withLoader(Component), mapStateToProps)

const provideDrizzle = Component => props =>
    <DrizzleProvider store={store} options={drizzleOptions}>
        <Component  {...props} />
    </DrizzleProvider >

export { withDrizzle, provideDrizzle };
