import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import { ThemeProvider } from "@material-ui/styles";
import { Provider } from "./context";
import Amplify from "aws-amplify";
import apiUtils from "./resources/api-utils";
import amplify from "./amplify.json";
import theme from "./theme";
import { throttle } from 'lodash';

Amplify.configure(amplify);

function App() {
  const [state, _setState] = useState({
    periodRaised: 0,
    wei_raised: 0,
    current_period: 0,
    remaining_period_time: 0,
    start_time_of_next_period: new Date(),
  });

  const delayedRefresh = React.useRef(
    throttle((q) => handleRefreshData(q), 5000)
  );

  const setState = (newState) => {
    var _newState = {
      ...state,
      ...newState,
    };
    _setState(_newState);
  };

  useEffect(() => {
    handleRefreshData();
    let id = setInterval(delayedRefresh.current, 30000);
    return () => clearInterval(id);
  }, []);

  const handleRefreshData = () =>
    apiUtils.getDappData().then((data) => setState(data));

  const getContext = () => {
    return {
      ...state,
      onRefreshData: handleRefreshData,
      onAddToContext: (element, name) =>
        _setState({ ...state, [name]: element }),
    };
  };

  return (
    <Provider value={getContext()}>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes />
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
