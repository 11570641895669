import React, { useState, useEffect } from "react";
import Countdown from "../Countdown";
import config from "../../config.json";
import { withContext } from "../../context";
import SaleProgress from "../SaleProgress";
import { I18nProvider, LOCALES } from "../../i18n";
import translate from "../../i18n/translate";
import CookieConsent from "react-cookie-consent";
import { weiToEth } from "utils/weiUtils";

function Landing(props) {
  var { history } = props;

  var languages = [
    "fr",
    "fr-FR",
    "fr-BE",
    "fr-CA",
    "fr-CH",
    "fr-LU",
    "es",
    "es-ES",
    "es-AR",
    "es-GT",
    "es-CR",
    "es-PA",
    "es-DO",
    "es-MX",
    "es-VE",
    "es-CO",
    "es-PE",
    "es-EC",
    "es-CL",
    "es-UY",
    "es-PY",
    "es-BO",
    "es-SV",
    "es-HN",
    "es-NI",
    "es-PR",
  ];

  function findLocale() {
    let i;
    for (i = 0; i < languages.length; i++) {
      if (navigator.language == languages[i]) {
        return navigator.language;
      }
    }
    return LOCALES.ENGLISH;
  }

  const [locale, setLocale] = useState(findLocale());

  return (
    <I18nProvider locale={locale}>
      <div>
        <div className="nk-wrap">
          <header
            className="nk-header page-header is-sticky is-shrink is-transparent is-light"
            id="header"
          >
            {/* Header @s */}
            <div className="header-main">
              <div className="header-container container">
                <div className="header-wrap">
                  {/* Logo @s */}
                  <div
                    className="header-logo logo animated"
                    data-animate="fadeInDown"
                    data-delay=".65"
                  >
                    <a href="./" className="logo-link">
                      <img
                        className="logo-dark"
                        src="images/logo.png"
                        srcSet="images/logo2x.png 2x"
                        alt="logo"
                      />
                      <img
                        className="logo-light"
                        src="images/logo.png"
                        srcSet="images/logo2x.png 2x"
                        alt="logo"
                      />
                    </a>
                  </div>
                  {/* Menu Toogle @s */}
                  <div className="header-nav-toggle">
                    <a
                      href="#"
                      className="navbar-toggle"
                      data-menu-toggle="header-menu"
                    >
                      <div className="toggle-line">
                        <span />
                      </div>
                    </a>
                  </div>
                  {/* Menu @s */}
                  <div className="header-navbar header-navbar-s1">
                    <nav className="header-menu" id="header-menu">
                      <ul
                        className="menu animated"
                        data-animate="fadeInDown"
                        data-delay=".75"
                      >
                        <li className="menu-item">
                          <a className="menu-link nav-link" href="#about">
                            {translate("menu-about")}
                          </a>
                        </li>
                        <li className="menu-item">
                          <a className="menu-link nav-link" href="#why">
                            {translate("menu-vision")}
                          </a>
                        </li>
                        {/**/}
                        <li className="menu-item">
                          <a className="menu-link nav-link" href="#benefits">
                            {translate("menu-benefits")}
                          </a>
                        </li>
                        <li className="menu-item">
                          <a className="menu-link nav-link" href="#token">
                            {translate("menu-auction")}
                          </a>
                        </li>
                        <li className="menu-item">
                          <a className="menu-link nav-link" href="#roadmap">
                            {translate("menu-roadmap")}
                          </a>
                        </li>
                        <li className="menu-item">
                          <a className="menu-link nav-link" href="#team">
                            {translate("menu-team")}
                          </a>
                        </li>
                        <li className="menu-item">
                          <a className="menu-link nav-link" href="#faqs">
                            {translate("menu-faq")}
                          </a>
                        </li>
                        <li className="menu-item">
                          <a className="menu-link nav-link" href="#contact">
                            {translate("menu-contact")}
                          </a>
                        </li>
                      </ul>
                      <ul
                        className="menu-btns menu-btns-s3 align-items-center animated"
                        data-animate="fadeInDown"
                        data-delay=".85"
                      >
                        <li className="language-switcher language-switcher-s1 toggle-wrap">
                          <a className="toggle-tigger">
                            {translate("language")}
                          </a>
                          <ul className="toggle-class toggle-drop toggle-drop-left drop-list drop-list-sm">
                            <li>
                              <a
                                className="active"
                                onClick={() => setLocale(LOCALES.ENGLISH)}
                              >
                                English
                              </a>
                            </li>
                            <li>
                              <a onClick={() => setLocale(LOCALES.FRENCH)}>
                                Français
                              </a>
                            </li>
                            <li>
                              <a onClick={() => setLocale(LOCALES.SPANISH)}>
                                Español
                              </a>
                            </li>
                          </ul>
                        </li>
                        {/*<li><a href="#" data-toggle="modal" data-target="#register-popup" className="btn btn-md btn-primary btn-outline" onClick={() => window.open(config.methaTokensale, "_self")}><span>Token Sale</span></a></li>*/}
                        <li>
                          <a
                            href="https://rinkeby.metha.life/"
                            className="btn btn-md btn-primary btn-outline"
                          >
                            <span>{translate("button-auction")}</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  {/* .header-navbar @e */}
                </div>
              </div>
            </div>
            {/* .header-main @e */}
            {/* Banner @s */}
            <div className="header-banner  my-background ov-h header-banner-jasmine ">
              {/*<div class="background-shape"></div>*/}
              <div className="nk-banner">
                <div className="banner banner-fs banner-single">
                  {/* .block @s */}
                  <div className="nk-block nk-block-header nk-block-sm my-auto">
                    <div className="container pt-5">
                      <div className="banner-caption text-center">
                        <h1
                          className="title title-xl-2 ttu animated"
                          data-animate="fadeInUp"
                          data-delay="0.7"
                        >
                          {translate("title")}
                        </h1>
                        <div className="row justify-content-center pb-3">
                          <div className="col-sm-11 col-xl-11 col-xxl-8">
                            <p
                              className="lead animated"
                              data-animate="fadeInUp"
                              data-delay="0.8"
                            >
                              {translate("subtitle")}
                            </p>
                          </div>
                        </div>
                        <div className="cpn-action">
                          <ul className="btn-grp mx-auto">
                            <li
                              className="animated"
                              data-animate="fadeInUp"
                              data-delay="0.9"
                            >
                              <a
                                href="#Documents"
                                className="btn btn-primary btn-round"
                              >
                                {translate("button-documents")}
                              </a>
                            </li>
                            <li
                              className="animated"
                              data-animate="fadeInUp"
                              data-delay={1}
                            >
                              <a
                                href="https://rinkeby.metha.life/"
                                className="menu-link btn btn-round btn-outline btn-primary"
                              >
                                {translate("button-auction")}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* .block @e */}
                  {/* .block @s */}
                  <div
                    className="nk-block nk-block-status animated"
                    data-animate="fadeInUp"
                    data-delay="0.9"
                  >
                    <div className="container container-xxl">
                      <div className="row gutter-vr-40px justify-content-between">
                        <div className="col-xxl-6 col-xl-5 col-lg-5">
                          <SaleProgress
                            periodRaised={props.periodRaised}
                            tokenSaleStats={props.tokenSaleStats}
                          />
                        </div>
                        <div className="col-xxl-5 col-xl-6 col-lg-7 text-center text-sm-left">
                          <div className="row justify-content-around gutter-vr-30px">
                            <div className="col-sm-4 col-md-6 col-lg-4 col-xl-5">
                              <div
                                className="status-info animated"
                                data-animate="fadeInUp"
                                data-delay="0.9"
                              >
                                <h6 className="title title-xxs tc-default status-title ttu">
                                  Total Raised
                                </h6>
                                <h3
                                  className="fz-3 fw-3 status-percent"
                                  style={{ marginTop: 16 }}
                                >
                                  {weiToEth(props.getWeiRaised)} ETH
                                </h3>
                                <div className="fz-8">
                                  Thanks to all Contributors
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-8 col-md-6 col-lg-7 col-xl-7">
                              <div
                                className="status-countdown float-sm-right animated"
                                data-animate="fadeInUp"
                                data-delay={1}
                              >
                                <h6 className="title title-xxs tc-default status-title ttu">
                                  This period ends in
                                </h6>
                                <div className="countdown justify-content-center justify-content-sm-start countdown-s3 countdown-s3-alt">
                                  <Countdown
                                    onRefreshData={props.onRefreshData}
                                    remainingPeriodTime={
                                      props.remainingPeriodTime
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* .block @e */}
                  <div className="nk-block nk-block-actions">
                    <div className="container container-xxl">
                      <div className="row gutter-vr-40px align-items-center">
                        <div className="col-sm-7 d-flex justify-content-center justify-content-sm-start">
                          <ul className="btn-grp btn-grp-break justify-content-center justify-content-sm-start gutter-vr-20px">
                            <li
                              className="animated"
                              data-animate="fadeInUp"
                              data-delay="0.8"
                            >
                              <a
                                href="https://www.youtube.com/watch?v=djaALnjcdYM"
                                className="link link-light link-break video-popup"
                              >
                                <em className="icon-circle icon-border icon-animation fas fa-play" />
                                <span>{translate("video-pop")}</span>
                              </a>
                            </li>
                            <li
                              className="animated"
                              data-animate="fadeInUp"
                              data-delay="0.9"
                            >
                              <a
                                href="#faqs"
                                className="link link-light link-break"
                              >
                                <em className="icon-circle icon-border far fa-lightbulb" />
                                <span>{translate("why-buy")}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="col-sm-5">
                          <ul
                            className="social-links social-links-s2 justify-content-center justify-content-sm-end animated"
                            data-animate="fadeInUp"
                            data-delay={1}
                          >
                            <li>
                              <a
                                href="https://twitter.com/MBiofund"
                                target="blank"
                              >
                                <em className="fab fa-twitter" />
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://t.me/methabiofund"
                                target="blank"
                              >
                                <em className="fab fa-telegram" />
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.facebook.com/MethaBiofund/"
                                target="blank"
                              >
                                <em className="fab fa-facebook-f" />
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.reddit.com/r/LongevityInvesting/"
                                target="blank"
                              >
                                <em className="fab fa-reddit" />
                              </a>
                            </li>
                            <li>
                              <a href="https://bitcointalk.org/" target="blank">
                                <em className="fab fa-bitcoin" />
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://github.com/Metha-Biofund"
                                target="blank"
                              >
                                <em className="fab fa-github" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="wrapper-twitcker"
                    className=" nk-block-actions"
                  ></div>
                  {/* <div id="wrapper-twitcker" className=" nk-block-actions"></div> **** this is for ticker fixed****/}
                </div>
              </div>
              {/* .nk-banner */}
              {/* Place Particle Js */}
              <div
                id="particles-bg"
                className="particles-container particles-bg"
                data-pt-base="#00c0fa"
                data-pt-base-op=".3"
                data-pt-line="#2b56f5"
                data-pt-line-op=".5"
                data-pt-shape="#00c0fa"
                data-pt-shape-op=".2"
              />
            </div>
            {/* .header-banner @e */}
          </header>
          <main className="nk-pages">
            {/*
      <section class="section section-r bg-white pb-0 ov-h">
          <div class="container">
              <div class="section-head section-head-sm wide-auto-sm text-center">
                  <h2 class="title title-xs title-xs-s1 tc-alternet animated" data-animate="fadeInUp"
                      data-delay=".1">Our Rating</h2>
              </div>
              <div class="row justify-content-center">
                  <div class="col-lg-10">
                      <!-- Block @s 
                      <div class="nk-block">
                          <div class="row gutter-vr-30px text-center">
                              <div class="col-md col-4">
                                  <div class="rating animated" data-animate="fadeInUp" data-delay=".2">
                                      <div class="rating-info">4.7<span>5</span></div>
                                      <div class="rating-title">ICO Bench</div>
                                  </div>
                              </div>
                              <div class="col-md col-4">
                                  <div class="rating animated" data-animate="fadeInUp" data-delay=".3">
                                      <div class="rating-info">A</div>
                                      <div class="rating-title">ICObazaar</div>
                                  </div>
                              </div>
                              <div class="col-md col-4">
                                  <div class="rating animated" data-animate="fadeInUp" data-delay=".4">
                                      <div class="rating-info">9.4<span>10</span></div>
                                      <div class="rating-title">ICORanker</div>
                                  </div>
                              </div>
                              <div class="col-md col-4">
                                  <div class="rating animated" data-animate="fadeInUp" data-delay=".5">
                                      <div class="rating-info">4.9<span>5</span></div>
                                      <div class="rating-title">Trackico</div>
                                  </div>
                              </div>
                              <div class="col-md col-4">
                                  <div class="rating animated" data-animate="fadeInUp" data-delay=".6">
                                      <div class="rating-info">5<span>5</span></div>
                                      <div class="rating-title">WiserICO</div>
                                  </div>
                              </div>
                              <div class="col-md col-4">
                                  <div class="rating animated" data-animate="fadeInUp" data-delay=".7">
                                      <div class="rating-info">9.1<span>10</span></div>
                                      <div class="rating-title">Fundico</div>
                                  </div>
                              </div>
                          </div>
                      </div><!-- Block @e 
                  </div><!-- .col 
              </div><!-- .row 
          </div>
      </section>*/}
            {/* // */}
            <section className="section bg-light-grad" id="about">
              <div className="container">
                {/* Block @s */}
                <div className="nk-block nk-block-features-s2">
                  <div className="row align-items-center gutter-vr-30px">
                    <div className="col-md-6">
                      <div
                        className="gfx mx-auto mx-lg-0 animated"
                        data-animate="fadeInUp"
                        data-delay=".1"
                      >
                        <img
                          src="images/gfx/isometric-research.png"
                          alt="gfx"
                        />
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-md-6">
                      {/* Section Head @s */}
                      <div className="nk-block-text text-center text-md-left">
                        <h6
                          className="title title-xs title-s1 tc-primary animated"
                          data-animate="fadeInUp"
                          data-delay=".2"
                        >
                          {translate("about-ticker")}
                        </h6>
                        <h2
                          className="title animated"
                          data-animate="fadeInUp"
                          data-delay=".3"
                        >
                          {translate("about-title")}
                        </h2>
                        <p
                          className="lead animated"
                          data-animate="fadeInUp"
                          data-delay=".4"
                        >
                          {translate("about-1")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".5"
                        >
                          {translate("about-2")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".6"
                        >
                          {translate("about-3")}
                        </p>
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                {/* .block @e */}
              </div>
            </section>
            {/* // */}
            <section className="section bg-white" id="why">
              <div className="container">
                {/* Block @s */}
                <div className="nk-block nk-block-lg nk-block-features-s2">
                  <div className="row align-items-center flex-row-reverse gutter-vr-30px">
                    <div className="col-md-6">
                      <div
                        className="gfx animated"
                        data-animate="fadeInUp"
                        data-delay=".1"
                      >
                        <img
                          src="images/gfx/isometric-conflict.png"
                          alt="gfx"
                        />
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-md-6">
                      {/* Section Head @s */}
                      <div className="nk-block-text">
                        <h6
                          className="title title-xs title-s1 tc-primary animated"
                          data-animate="fadeInUp"
                          data-delay=".2"
                        >
                          {translate("vision-ticker-1")}
                        </h6>
                        <h2
                          className="title animated"
                          data-animate="fadeInUp"
                          data-delay=".3"
                        >
                          {translate("vision-title-1")}
                        </h2>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".4"
                        >
                          {translate("vision-1-1")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".5"
                        >
                          {translate("vision-1-2")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".6"
                        >
                          {translate("vision-1-3")}
                        </p>
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                {/* .block @e */}
                {/* Block @s */}
                <div className="nk-block nk-block-lg nk-block-features-s2">
                  <div className="row align-items-center gutter-vr-30px">
                    <div className="col-md-6">
                      <div
                        className="gfx animated"
                        data-animate="fadeInUp"
                        data-delay=".1"
                      >
                        <img
                          src="images/gfx/this-is-mandanga-01.png"
                          alt="gfx"
                        />
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-md-6">
                      {/* Section Head @s */}
                      <div className="nk-block-text">
                        <h6
                          className="title title-xs title-s1 tc-primary animated"
                          data-animate="fadeInUp"
                          data-delay=".2"
                        >
                          {translate("vision-ticker-2")}
                        </h6>
                        <h2
                          className="title animated"
                          data-animate="fadeInUp"
                          data-delay=".3"
                        >
                          {translate("vision-title-2")}
                        </h2>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".4"
                        >
                          {translate("vision-2-1")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".5"
                        >
                          {translate("vision-2-2")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".6"
                        >
                          {translate("vision-2-3")}
                        </p>
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                {/* .block @e */}
                {/* Block @s */}
                <div className="nk-block nk-block-features-s2">
                  <div className="row align-items-center flex-row-reverse gutter-vr-30px">
                    <div className="col-md-6">
                      <div
                        className="gfx animated"
                        data-animate="fadeInUp"
                        data-delay=".1"
                      >
                        <img src="images/gfx/gfx-u-light.png" alt="gfx" />
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-md-6">
                      {/* Section Head @s */}
                      <div className="nk-block-text">
                        <h6
                          className="title title-xs title-s1 tc-primary animated"
                          data-animate="fadeInUp"
                          data-delay=".2"
                        >
                          {translate("vision-ticker-3")}
                        </h6>
                        <h2
                          className="title animated"
                          data-animate="fadeInUp"
                          data-delay=".3"
                        >
                          {translate("vision-title-3")}
                        </h2>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".4"
                        >
                          {translate("vision-3-1")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".5"
                        >
                          {translate("vision-3-2")}
                        </p>
                        <p
                          className="animated"
                          data-animate="fadeInUp"
                          data-delay=".6"
                        >
                          {translate("vision-3-3")}
                        </p>
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                {/* .block @e */}
              </div>
            </section>
            <CookieConsent
              location="top"
              buttonText="I consent."
              cookieName="myAwesomeCookieName2"
              style={{ background: "#2B373B", opacity: 0.8 }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              expires={150}
            >
              This website uses cookies to enhance the user experience.{" "}
              <span style={{ fontSize: "10px" }}>
                Read more about{" "}
                <a href="https://metha.life/cookies">our cookies</a>.
              </span>
            </CookieConsent>
            {/* // */}
            <section className="section bg-light" id="benefits">
              <div className="background-shape bs-reverse" />
              <div className="container">
                <div className="section-head section-head-s9 wide-sm">
                  <h6
                    className="title title-xs title-s1 tc-primary animated"
                    data-animate="fadeInUp"
                    data-delay=".1"
                  >
                    {translate("benefits-ticker")}
                  </h6>
                  <h2
                    className="title animated"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    {translate("benefits-title")}
                  </h2>
                  <p
                    className="animated"
                    data-animate="fadeInUp"
                    data-delay=".3"
                  >
                    {translate("benefits-subtitle")}
                  </p>
                </div>
                {/* Block @s */}
                <div className="nk-block">
                  <div className="row gutter-vr-40px justify-content-center">
                    <div className="col-lg-4 col-md-4">
                      <div
                        className="feature feature-s8 feature-s8-alt feature-center card card-full-lg card-md animated"
                        data-animate="fadeInUp"
                        data-delay=".4"
                      >
                        <div className="feature-icon feature-icon-lg feature-icon-lg-s2">
                          <img src="images/icons/icon-i.png" alt="feature" />
                        </div>
                        <div className="feature-text feature-text-s8">
                          <h4 className="title title-sm title-thin title-s5">
                            <span>{translate("benefits-1-title")}</span>
                            <span>{translate("benefits-1-sub")}</span>
                          </h4>
                          <p>{translate("benefits-1-des")}</p>
                          <a
                            href="#"
                            className="link link-primary link-feature-s1"
                          >
                            <em className="link-icon icon-circle icon-circle-md ti ti-arrow-right" />
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-lg-4 col-md-4">
                      <div
                        className="feature feature-s8 feature-s8-alt feature-center card card-full-lg card-md animated"
                        data-animate="fadeInUp"
                        data-delay=".5"
                      >
                        <div className="feature-icon feature-icon-lg feature-icon-lg-s2">
                          <img src="images/icons/icon-j.png" alt="feature" />
                        </div>
                        <div className="feature-text feature-text-s8">
                          <h4 className="title title-sm title-thin title-s5">
                            <span>{translate("benefits-2-title")}</span>
                            <span>{translate("benefits-2-sub")}</span>
                          </h4>
                          <p>{translate("benefits-2-des")}</p>
                          <a
                            href="#"
                            className="link link-primary link-feature-s1"
                          >
                            <em className="link-icon icon-circle icon-circle-md ti ti-arrow-right" />
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-lg-4 col-md-4">
                      <div
                        className="feature feature-s8 feature-s8-alt feature-center card card-full-lg card-md animated"
                        data-animate="fadeInUp"
                        data-delay=".6"
                      >
                        <div className="feature-icon feature-icon-lg feature-icon-lg-s2">
                          <img src="images/icons/icon-h.png" alt="feature" />
                        </div>
                        <div className="feature-text feature-text-s8">
                          <h4 className="title title-sm title-thin title-s5">
                            <span>{translate("benefits-3-title")}</span>
                            <span>{translate("benefits-3-sub")}</span>
                          </h4>
                          <p>{translate("benefits-3-des")}</p>
                          <a
                            href="#"
                            className="link link-primary link-feature-s1"
                          >
                            <em className="link-icon icon-circle icon-circle-md ti ti-arrow-right" />
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                  <div className="d-flex justify-content-center pdt-r">
                    <ul
                      className="btn-grp animated"
                      data-animate="fadeInUp"
                      data-delay=".7"
                    >
                      <li>
                        <a
                          href="https://rinkeby.metha.life/"
                          className="btn btn-md btn-grad"
                        >
                          {translate("button-auction")}
                        </a>
                      </li>
                      <li>
                        <a
                          href="#Documents"
                          className="btn btn-md btn-outline btn-grad on-bg-light"
                        >
                          {translate("button-documents")}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* .block @e */}
              </div>
            </section>
            {/* // 
      <section class="section bg-white" id="benifits">
          <div class="container">
              <div class="section-head section-head-s9 wide-sm">
                  <h6 class="title title-xs title-s1 tc-primary animated" data-animate="fadeInUp" data-delay=".1">
                      ICO Crypto Feature</h6>
                  <h2 class="title animated" data-animate="fadeInUp" data-delay=".2">Ecosystem key features</h2>
                  <p class="animated" data-animate="fadeInUp" data-delay=".3">The ICO Crypto Team combines a
                      passion for esports, industry experise &amp; proven record in finance, development,
                      marketing.</p>
              </div>
              <!-- Block @s 
              <div class="nk-block nk-block-features">
                  <div class="row gutter-vr-60px gutter-100px">
                      <div class="col-lg-6">
                          <div class="feature feature-s12 animated" data-animate="fadeInUp" data-delay=".4">
                              <div class="feature-icon feature-icon-lg-s1 m-lg-0">
                                  <img src="images/icons/icon-d-light.png" alt="feature">
                              </div>
                              <div class="feature-text feature-text-s2">
                                  <p>ICO Crypto makes <strong> you the sole owner of a secure </strong>
                                      decentralize registry for your collection an products</p>
                              </div>
                          </div>
                      </div><!-- .col 
                      <div class="col-lg-6">
                          <div class="feature feature-s12 animated" data-animate="fadeInUp" data-delay=".5">
                              <div class="feature-icon feature-icon-lg-s1 m-lg-0">
                                  <img src="images/icons/icon-e-light.png" alt="feature">
                              </div>
                              <div class="feature-text feature-text-s2">
                                  <p>The registry is a <strong>tamper-proof</strong> that uses <strong>dolor sit
                                          amet,</strong> conse ctetur sed eiusmod tempor incidid labore Lorem
                                      consectetur adipiscing.</p>
                              </div>
                          </div>
                      </div><!-- .col 
                      <div class="col-lg-6">
                          <div class="feature feature-s12 animated" data-animate="fadeInUp" data-delay=".6">
                              <div class="feature-icon feature-icon-lg-s1 m-lg-0">
                                  <img src="images/icons/icon-f-light.png" alt="feature">
                              </div>
                              <div class="feature-text feature-text-s2">
                                  <p>Provide your customer with a <strong>lorem ipsum dolor sit amet, conse ctetur
                                          sed</strong> eiusmod tempor incidid labore Lorem ipsum dolor.
                                  </p>
                              </div>
                          </div>
                      </div><!-- .col 
                      <div class="col-lg-6">
                          <div class="feature feature-s12 animated" data-animate="fadeInUp" data-delay=".7">
                              <div class="feature-icon feature-icon-lg-s1 m-lg-0">
                                  <img src="images/icons/icon-g-light.png" alt="feature">
                              </div>
                              <div class="feature-text feature-text-s2">
                                  <p>ICO Crypto the prowess of blockchain <strong>labore et dolore</strong> dolor
                                      sit amet, conse <strong>Ctetur sed</strong> eiusmod tempor labore dolor
                                      adipiscing.</p>
                              </div>
                          </div>
                      </div><!-- .col 
                  </div><!-- .row 
              </div><!-- .block @s 
          </div>
      </section>*/}
            {/* // 
      <section class="section bg-light">
          <div class="container">
              <div class="section-head section-head-s9 wide-md">
                  <h6 class="title title-xs title-s1 tc-primary animated" data-animate="fadeInUp" data-delay=".1">
                      ICO Market</h6>
                  <h2 class="title animated" data-animate="fadeInUp" data-delay=".2">Introducing the ICO
                      Marketplace</h2>
                  <div class="wide-sm">
                      <p class="animated" data-animate="fadeInUp" data-delay=".3">Blockchain-powered marketplace
                          which connects buyer and Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
                  </div>
              </div>
              <!-- Block @s 
              <div class="nk-block nk-block-text-wrap">
                  <div class="row align-items-center gutter-vr-30px">
                      <div class="col-lg-6">
                          <div class="nk-block-img animated" data-animate="fadeInUp" data-delay=".4">
                              <img src="images/jasmine/laptop-mobile-a.png" alt="app">
                          </div>
                      </div><!-- .col 
                      <div class="col-lg-6">
                          <div class="nk-block-text">
                              <ul class="list list-check list-check-s3">
                                  <li class="animated" data-animate="fadeInUp" data-delay=".5">The Platform will
                                      earn premium libero tempore, cum soluta nobis keep their digital coins est
                                      eligendi optio.</li>
                                  <li class="animated" data-animate="fadeInUp" data-delay=".55">At aut reiciendis
                                      voluptatibus maiores alias conse quatur aut perferendis. Contributor payouts
                                      perfectly simple and easy</li>
                                  <li class="animated" data-animate="fadeInUp" data-delay=".6">Ut enim ad minim
                                      veniam, quis nostrud exerc itation identification is stored in an encrypted
                                      ullamco laboris nisi.</li>
                                  <li class="animated" data-animate="fadeInUp" data-delay=".65">Owners of
                                      cryptocurrency keep their digital coins in ICO digital wallet. A
                                      coin-holder’s identification is stored in an encrypted address that they
                                      have control.</li>
                              </ul>
                          </div>
                      </div><!-- .col 
                  </div><!-- .row 
              </div><!-- .block @e 
          </div>
      </section>*/}
            {/* /
                        <section className="section my-background-stat" id="token">
                            {/* Block @s 
                            <div className="container">
                                <div className="section-head section-head-s9 wide-md">
                                    <h6 className="title title-xs title-s1 tc-primary animated" data-animate="fadeInUp" data-delay=".1">
                                        {translate("auction-ticker")}</h6>
                                    <h2 className="title animated" data-animate="fadeInUp" data-delay=".2">{translate("auction-title")}</h2>
                                    <div className="wide-sm">
                                        <p className="animated" data-animate="fadeInUp" data-delay=".3">{translate("auction-sub")}</p>
                                    </div>
                                </div>
                                <div className="tab-content nk-preview-content">
                                    <div className="tab-pane fade show active" id="token-default-01">
                                        {/* Copy from here 
                                        <section className="bg-white">
                                            <div className="section-l section-tokensale">
                                                <div className="container">
                                                    {/* Block @s 
                                                    <div className="nk-block nk-block-token mgb-m30">
                                                        <div className="row">
                                                            <div className="col-lg-7">
                                                                <div className="token-info bg-white">
                                                                    <h4 className="title title-md mb-2 text-sm-center">Token Info</h4>
                                                                    <table className="table table-token">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="table-head">Next Period </td>
                                                                                <td className="table-des">15th Nov 2020 12:00 GMT</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="table-head">Meth / Period</td>
                                                                                <td className="table-des">9000 METH</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="table-head">Total METH</td>
                                                                                <td className="table-des">330,000</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="table-head">Total Raised</td>
                                                                                <td className="table-des">35.541,02 ETH</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="table-head">Meth Address</td>
                                                                                <td className="table-des">Not Deployed</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="table-head">Sale Address</td>
                                                                                <td className="table-des">Not Deployed</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="table-head">Token Value</td>
                                                                                <td className="table-des">1 ETH = 9,8 METH</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="table-head">Accepted</td>
                                                                                <td className="table-des">ETH</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>{/* .col 
                                                            <div className="col-lg-5">
                                                                <div className="token-status bg-white">
                                                                    <h4 className="title title-md">Period 37 ends in :</h4>
                                                                    <div className="token-countdown countdown" data-date="2019/12/26" />
                                                                    <div className="progress-wrap progress-wrap-point">
                                                                        <span className="progress-info">Raised so far <span>1.830 ETH</span></span>
                                                                        <div className="progress-bar">
                                                                            <div className="progress-percent bg-grad" data-percent={30} />
                                                                            <div className="progress-point progress-point-1">Min <span>1.750 ETH</span></div>
                                                                            <div className="progress-point progress-point-2">Max <span>4.750 ETH</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <a href="https://rinkeby.metha.life/" className="btn btn-grad">{translate("button-contribute")}</a>
                                                                    <span className="token-min">Minimum Contribution: 0,1 ETH</span>
                                                                </div>
                                                                <div className="bonus bg-white">
                                                                    <div className="bonus-info">
                                                                        <div className="bonus-percent">37th <small>Period</small></div>
                                                                        <div className="bonus-date">End at 30 Nov, 2020</div>
                                                                        <span className="bonus-badge badge-xs">Now</span>
                                                                    </div>
                                                                    <div className="bonus-info">
                                                                        <div className="bonus-percent">38th <small>Period</small></div>
                                                                        <div className="bonus-date">Start at 01 Dec, 2020</div>
                                                                    </div>
                                                                </div>
                                                            </div>{/* .col 
                                                        </div>{/* .row 
                                                    </div>{/* .block @e 
                                                </div>
                                            </div>{/* .section-tokensale 
                                        </section>
                                        {/* Stop here 
                                    </div>
                                </div>{/* .block @e 
                                <div className="nk-block">
                                    <div className="bg-white">
                                        <div className="section section-tokendes">
                                            <div className="container">
                                                {/* Block @s 
                                                <div className="nk-block nk-block-alocation mgb-m30">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="token-alocate-item">
                                                                <div className="token-alocate-graph">
                                                                    <span>{translate("auction-chart-1")}</span>
                                                                    <canvas className="chart-canvas" id="token-alocate" />
                                                                </div>
                                                                <ul className="chart-data" data-canvas="token-alocate" data-canvas-type="doughnut">
                                                                    <li data-color="#223fa8" data-title="Metha Auction" data-amount={70} />
                                                                    <li data-color="#8fa0df" data-title="Reserve Fund" data-amount={10} />
                                                                    <li data-color="#4aa0f6" data-title="Team and Founders" data-amount={10} />
                                                                    <li data-color="#72a3f4" data-title="Event Stash" data-amount={10} />
                                                                </ul>
                                                            </div>
                                                        </div>{/* .col 
                                                        <div className="col-lg-6">
                                                            <div className="token-alocate-item">
                                                                <div className="token-alocate-graph">
                                                                    <span>{translate("auction-chart-2")}</span>
                                                                    <canvas className="chart-canvas" id="fund-alocate" />
                                                                </div>
                                                                <ul className="chart-data" data-canvas="fund-alocate">
                                                                    <li data-color="#15216d" data-title="Research Project A" data-amount={40} />
                                                                    <li data-color="#4aa0f6" data-title="Research Project B" data-amount={12} />
                                                                    <li data-color="#223fa8" data-title="Research Project C" data-amount={18} />
                                                                    <li data-color="#72a3f4" data-title="Research Project D" data-amount={6} />
                                                                    <li data-color="#6ad9ac" data-title="Marketing/Exposure" data-amount={10} />
                                                                    <li data-color="#8fa0df" data-title="Legal Expenses" data-amount={4} />
                                                                    <li data-color="#4d6fe9" data-title="Audits" data-amount={6} />
                                                                </ul>
                                                            </div>
                                                        </div>{/* .col 
                                                    </div>{/* .row 
                                                </div>{/* .block @e 
                                            </div>
                                        </div>{/* .section-tokendes 
                                    </div>
                                    {/* Stop here 
                                </div>{/* .block @e 
                            </div>
                        </section>/ */}
            {/* // */}
            <section className="section bg-light-grad" id="Documents">
              <div className="container">
                <div className="section-head section-head-s9 wide-md">
                  <h6
                    className="title title-xs title-s1 tc-primary animated"
                    data-animate="fadeInUp"
                    data-delay=".1"
                  >
                    {translate("documents-ticker")}
                  </h6>
                  <h2
                    className="title animated"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    {translate("documents-title")}
                  </h2>
                  <div className="wide-sm">
                    <p
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".3"
                    >
                      {translate("documents-sub")}
                    </p>
                  </div>
                </div>
                {/* Block @s */}
                <div className="nk-block nk-block-features">
                  <div className="row gutter-vr-30px">
                    <div className="col-xl-3 col-sm-6 col-mb-10">
                      <div
                        className="doc doc-s2 bg-white animated"
                        data-animate="fadeInUp"
                        data-delay=".4"
                      >
                        <div className="doc-photo no-hover">
                          <img src="images/docs/alt-sm-a.png" alt="doc" />
                        </div>
                        <div className="doc-text">
                          <h6 className="doc-title title-xs-alt">Whitepaper</h6>
                          <ul className="btn-grp gutter-10px">
                            <li>
                              <a
                                className="btn btn-outline btn-xxs btn-auto btn-light"
                                href="./documents/Metha_Whitepaper_Draft_20200313.pdf"
                                target="blank"
                              >
                                ENG
                              </a>
                            </li>
                            {/* /
                            <li>
                              <a
                                className="btn btn-outline btn-xxs btn-auto btn-light"
                                href="#"
                              >
                                FR
                              </a>
                            </li>
                            <li>
                              <a
                                className="btn btn-outline btn-xxs btn-auto btn-light"
                                href="#"
                              >
                                ES
                              </a>
                            </li>
                            / */}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-xl-3 col-sm-6 col-mb-10">
                      <div
                        className="doc doc-s2 bg-white animated"
                        data-animate="fadeInUp"
                        data-delay=".5"
                      >
                        <div className="doc-photo no-hover">
                          <img src="images/docs/alt-sm-b.png" alt="doc" />
                        </div>
                        <div className="doc-text">
                          <h6 className="doc-title title-xs-alt">D.F.E ©</h6>
                          <ul className="btn-grp gutter-10px">
                            <li>
                              <a
                                className="btn btn-outline btn-xxs btn-auto btn-light"
                                href="#"
                              >
                                Soon...
                              </a>
                            </li>
                            
                            
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-xl-3 col-sm-6 col-mb-10">
                      <div
                        className="doc doc-s2 bg-white animated"
                        data-animate="fadeInUp"
                        data-delay=".6"
                      >
                        <div className="doc-photo no-hover">
                          <img src="images/docs/alt-sm-c.png" alt="doc" />
                        </div>
                        <div className="doc-text">
                          <h6 className="doc-title title-xs-alt">
                            {translate("documents-privacy")}
                          </h6>
                          <ul className="btn-grp gutter-10px">
                            <li>
                              <a
                                className="btn btn-outline btn-xxs btn-auto btn-light"
                                href="#"
                              >
                                Soon...
                              </a>
                            </li>
                            
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-xl-3 col-sm-6 col-mb-10">
                      <div
                        className="doc doc-s2 bg-white animated"
                        data-animate="fadeInUp"
                        data-delay=".7"
                      >
                        <div className="doc-photo no-hover">
                          <img src="images/docs/alt-sm-d.png" alt="doc" />
                        </div>
                        <div className="doc-text">
                          <h6 className="doc-title title-xs-alt">
                            {translate("documents-partner")}
                          </h6>
                          <ul className="btn-grp gutter-10px">
                            <li>
                              <a
                                className="btn btn-outline btn-xxs btn-auto btn-light"
                                href="#"
                              >
                                Soon...
                              </a>
                            </li>
                            
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                {/* .block @e */}
              </div>
            </section>
            {/* // */}
            <section className="section my-background-stat" id="roadmap">
              <div className="container">
                <div className="section-head section-head-s9 wide-md">
                  <h6
                    className="title title-xs title-s1 tc-primary animated"
                    data-animate="fadeInUp"
                    data-delay=".1"
                  >
                    {translate("roadmap-ticker")}
                  </h6>
                  <h2
                    className="title animated"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    {translate("roadmap-title")}
                  </h2>
                  <div className="wide-sm">
                    <p
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".3"
                    >
                      {translate("roadmap-sub")}
                    </p>
                  </div>
                </div>
                {/* Section Head @s */}
                {/* Block @s */}
                <div className="nk-block nk-block-left">
                  <div
                    className="roadmap-all mgb-m50 animated"
                    data-animate="fadeInUp"
                    data-delay=".4"
                  >
                    <div className="roadmap-wrap roadmap-wrap-done roadmap-wrap-s1 roadmap-wrap-s1-alt mb-0 ml-0">
                      <div className="row no-gutters">
                        <div className="col-lg">
                          <div className="roadmap roadmap-s1  roadmap-s1-alt roadmap-done text-lg-center">
                            <div className="roadmap-step roadmap-step-s1">
                              <div className="roadmap-head roadmap-head-s1">
                                <span className="roadmap-time roadmap-time-s1">
                                  2017
                                </span>
                                <span className="roadmap-title roadmap-title-s1">
                                  {translate("roadmap-1-1")}
                                </span>
                              </div>
                              <ul className="roadmap-step-list roadmap-step-list-s1">
                                <li>{translate("roadmap-1-2")}</li>
                                <li>{translate("roadmap-1-3")}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* .col */}
                        <div className="col-lg">
                          <div className="roadmap roadmap-s1  roadmap-s1-alt roadmap-done text-lg-center">
                            <div className="roadmap-step roadmap-step-s1">
                              <div className="roadmap-head roadmap-head-s1">
                                <span className="roadmap-time roadmap-time-s1">
                                  2018
                                </span>
                                <span className="roadmap-title roadmap-title-s1">
                                  {translate("roadmap-2-1")}
                                </span>
                              </div>
                              <ul className="roadmap-step-list roadmap-step-list-s1">
                                <li>{translate("roadmap-2-2")}</li>
                                <li>{translate("roadmap-2-3")}</li>
                                <li>W{translate("roadmap-2-4")}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* .col */}
                        <div className="col-lg">
                          <div className="roadmap roadmap-s1  roadmap-s1-alt roadmap-done text-lg-center">
                            <div className="roadmap-step roadmap-step-s1">
                              <div className="roadmap-head roadmap-head-s1">
                                <span className="roadmap-time roadmap-time-s1">
                                  2019
                                </span>
                                <span className="roadmap-title roadmap-title-s1">
                                  {translate("roadmap-3-1")}
                                </span>
                              </div>
                              <ul className="roadmap-step-list roadmap-step-list-s1">
                                <li>{translate("roadmap-3-2")}</li>
                                <li>{translate("roadmap-3-3")}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* .col */}
                        <div className="col-lg">
                          <div className="roadmap roadmap-s1  roadmap-s1-alt roadmap-done text-lg-center">
                            <div className="roadmap-step roadmap-step-s1">
                              <div className="roadmap-head roadmap-head-s1">
                                <span className="roadmap-time roadmap-time-s1">
                                  2020 H1
                                </span>
                                <span className="roadmap-title roadmap-title-s1">
                                  {translate("roadmap-4-1")}
                                </span>
                              </div>
                              <ul className="roadmap-step-list roadmap-step-list-s1">
                                <li>{translate("roadmap-4-2")}</li>
                                <li>{translate("roadmap-4-3")}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* .col */}
                      </div>
                      {/* .row */}
                    </div>
                    <div className="roadmap-wrap roadmap-wrap-s1 roadmap-wrap-s1-alt mb-0 ml-0">
                      <div className="row flex-row-reverse no-gutters">
                        <div className="col-lg flex-row-rereverse">
                          <div className="roadmap roadmap-done roadmap-s1 roadmap-s1-alt text-lg-center">
                            <div className="roadmap-step roadmap-step-s1">
                              <div className="roadmap-head roadmap-head-s1">
                                <span className="roadmap-time roadmap-time-s1">
                                  2020 H2
                                </span>
                                <span className="roadmap-title roadmap-title-s1">
                                  {translate("roadmap-5-1")}
                                </span>
                              </div>
                              <ul className="roadmap-step-list roadmap-step-list-s1">
                                <li>I{translate("roadmap-5-2")}</li>
                                <li>{translate("roadmap-5-3")}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* .col */}
                        <div className="col-lg flex-row-rereverse">
                          <div className="roadmap roadmap-done roadmap-s1 roadmap-s1-alt text-lg-center">
                            <div className="roadmap-step roadmap-step-s1">
                              <div className="roadmap-head roadmap-head-s1">
                                <span className="roadmap-time roadmap-time-s1">
                                  2021 H2
                                </span>
                                <span className="roadmap-title roadmap-title-s1">
                                  {translate("roadmap-6-1")}
                                </span>
                              </div>
                              <ul className="roadmap-step-list roadmap-step-list-s1">
                                <li>{translate("roadmap-6-2")}</li>
                                <li>{translate("roadmap-6-3")}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* .col */}
                        <div className="col-lg">
                          <div className="roadmap roadmap-current roadmap-s1  roadmap-s1-alt text-lg-center">
                            <div className="roadmap-step roadmap-step-s1">
                              <div className="roadmap-head roadmap-head-s1">
                                <span className="roadmap-time roadmap-time-s1">
                                  2022 Q1
                                </span>
                                <span className="roadmap-title roadmap-title-s1">
                                  {translate("roadmap-7-1")}
                                </span>
                              </div>
                              <ul className="roadmap-step-list roadmap-step-list-s1">
                                <li>{translate("roadmap-7-2")}</li>
                                <li>{translate("roadmap-7-3")}</li>
                                <li>{translate("roadmap-7-4")}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* .col */}
                      </div>
                      {/* .row */}
                    </div>
                    <div className="roadmap-wrap roadmap-wrap-s1 roadmap-wrap-s1-alt mb-lg-0 ml-0">
                      <div className="row no-gutters">
                        <div className="col-lg">
                          <div className="roadmap roadmap-s1  roadmap-s1-alt text-lg-center">
                            <div className="roadmap-step roadmap-step-s1">
                              <div className="roadmap-head roadmap-head-s1">
                                <span className="roadmap-time roadmap-time-s1">
                                  2022 Q2
                                </span>
                                <span className="roadmap-title roadmap-title-s1">
                                  {translate("roadmap-8-1")}
                                </span>
                              </div>
                              <ul className="roadmap-step-list roadmap-step-list-s1">
                                <li>{translate("roadmap-8-2")}</li>
                                <li>{translate("roadmap-8-3")}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* .col */}
                        <div className="col-lg">
                          <div className="roadmap roadmap-s1  roadmap-s1-alt text-lg-center">
                            <div className="roadmap-step roadmap-step-s1">
                              <div className="roadmap-head roadmap-head-s1">
                                <span className="roadmap-time roadmap-time-s1">
                                  2022 Q3
                                </span>
                                <span className="roadmap-title roadmap-title-s1">
                                  {translate("roadmap-9-1")}
                                </span>
                              </div>
                              <ul className="roadmap-step-list roadmap-step-list-s1">
                                <li>{translate("roadmap-9-2")}</li>
                                <li>{translate("roadmap-9-3")}</li>
                                <li>{translate("roadmap-9-4")}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* .col */}
                        <div className="col-lg">
                          <div className="roadmap roadmap-s1  roadmap-s1-alt text-lg-center">
                            <div className="roadmap-step roadmap-step-s1">
                              <div className="roadmap-head roadmap-head-s1">
                                <span className="roadmap-time roadmap-time-s1">
                                  2022 Q4
                                </span>
                                <span className="roadmap-title roadmap-title-s1">
                                  {translate("roadmap-10-1")}
                                </span>
                              </div>
                              <ul className="roadmap-step-list roadmap-step-list-s1">
                                <li>{translate("roadmap-10-2")}</li>
                                <li>{translate("roadmap-10-3")}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* .col */}
                        <div className="col-lg">
                          <div className="roadmap roadmap-s1 roadmap-s1-alt text-lg-center">
                            <div className="roadmap-step roadmap-step-s1">
                              <div className="roadmap-head roadmap-head-s1">
                                <span className="roadmap-time roadmap-time-s1">
                                  2023
                                </span>
                                <span className="roadmap-title roadmap-title-s1">
                                  {translate("roadmap-11-1")}
                                </span>
                              </div>
                              <ul className="roadmap-step-list roadmap-step-list-s1">
                                <li>{translate("roadmap-11-2")}</li>
                                <li>{translate("roadmap-11-3")}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* .col */}
                      </div>
                      {/* .row */}
                    </div>
                  </div>
                </div>
                {/* .block @e */}
              </div>
            </section>
            {/* // */}
            <section className="section bg-light-alt" id="team">
              <div className="background-shape bs-right" />
              <div className="container">
                <div className="section-head section-head-s9 wide-md">
                  <h6
                    className="title title-xs title-s1 tc-primary animated"
                    data-animate="fadeInUp"
                    data-delay=".1"
                  >
                    {translate("team-ticker")}
                  </h6>
                  <h2
                    className="title animated"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    {translate("team-title")}
                  </h2>
                  <div className="wide-sm">
                    <p
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".3"
                    >
                      {translate("team-sub")}
                    </p>
                  </div>
                </div>
                {/* Block @s */}
                {/* Block @s */}
                <div className="nk-block nk-block-team-list team-list">
                  <div className="row justify-content-center">
                    <div className="col-lg-3 col-mb-6">
                      <div className="team team-s5">
                        <div className="team-photo team-photo-s1">
                          <img
                            src="images/team/foto_pepe.jpeg"
                            alt="team"
                            className="no-bdrs"
                          />
                          <a
                            href="#team-popup-9"
                            className="team-show content-popup"
                          />
                        </div>
                        <h5 className="team-name title title-md">
                          José Víctor <br />
                          Martínez
                        </h5>
                        <span className="team-position">CEO &amp; Founder</span>
                        <ul className="team-social team-social-vr team-social-s2">
                          <li>
                            <a href="https://www.facebook.com/MethaBiofund/">
                              <em className="fab fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="https://twitter.com/MBiofund">
                              <em className="fab fa-twitter" />
                            </a>
                          </li>
                          <li>
                            <a href="https://victor.metha.life">
                              <em className="fab fa-linkedin-in" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* Start .team-profile  */}
                      <div id="team-popup-9" className="team-popup mfp-hide">
                        <a title="Close" className="mfp-close">
                          ×
                        </a>
                        <div className="row align-items-start">
                          <div className="col-md-6">
                            <div className="team-photo">
                              <img
                                src="images/team/foto_pepe.jpeg"
                                alt="team"
                              />
                            </div>
                          </div>
                          {/* .col  */}
                          <div className="col-md-6">
                            <div className="team-popup-info pl-md-3">
                              <h3 className="team-name title title-lg pt-4">
                                José Víctor Martínez
                              </h3>
                              <p className="team-position">
                                CEO &amp; Founder{" "}
                              </p>
                              <ul className="team-social mb-4">
                                <li>
                                  <a href="https://www.facebook.com/MethaBiofund/">
                                    <em className="fab fa-facebook-f" />
                                  </a>
                                </li>
                                <li>
                                  <a href="https://victor.metha.life">
                                    <em className="fab fa-linkedin-in" />
                                  </a>
                                </li>
                              </ul>
                              <p>{translate("jose-victor-bio-1")}</p>
                              <p>{translate("jose-victor-bio-2")}</p>
                              <div className="progress-list">
                                <div className="progress-wrap">
                                  <div className="progress-title">
                                    Solidity{" "}
                                    <span className="progress-amount">84%</span>
                                  </div>
                                  <div className="progress-bar progress-bar-xs bg-black-10">
                                    <div
                                      className="progress-percent bg-primary"
                                      data-percent={84}
                                    />
                                  </div>
                                </div>
                                <div className="progress-wrap">
                                  <div className="progress-title">
                                    Javascript
                                    <span className="progress-amount">96%</span>
                                  </div>
                                  <div className="progress-bar progress-bar-xs bg-black-10">
                                    <div
                                      className="progress-percent bg-primary"
                                      data-percent={96}
                                    />
                                  </div>
                                </div>
                                <div className="progress-wrap">
                                  <div className="progress-title">
                                    React
                                    <span className="progress-amount">78%</span>
                                  </div>
                                  <div className="progress-bar progress-bar-xs bg-black-10">
                                    <div
                                      className="progress-percent bg-primary"
                                      data-percent={78}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* .col  */}
                        </div>
                        {/* .row  */}
                      </div>
                      {/* .team-profile  */}
                    </div>
                    {/* .col */}
                    <div className="col-lg-3 col-mb-6">
                      <div className="team team-s5">
                        <div className="team-photo team-photo-s1">
                          <img
                            src="images/team/foto_maldo.jpg"
                            alt="team"
                            className="no-bdrs"
                          />
                          <a
                            href="#team-popup-10"
                            className="team-show content-popup"
                          />
                        </div>
                        <h5 className="team-name title title-md">
                          Pablo <br />
                          Maldonado
                        </h5>
                        <span className="team-position">
                          CTO &amp; Software Engineer
                        </span>
                        <ul className="team-social team-social-vr team-social-s2">
                          <li>
                            <a href="https://www.facebook.com/MethaBiofund/">
                              <em className="fab fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="https://twitter.com/MBiofund">
                              <em className="fab fa-twitter" />
                            </a>
                          </li>
                          <li>
                            <a href="https://www.linkedin.com/in/pablo-maldonado-turci-10b17164/">
                              <em className="fab fa-linkedin-in" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* Start .team-popup  */}
                      <div id="team-popup-10" className="team-popup mfp-hide">
                        <a title="Close" className="mfp-close">
                          ×
                        </a>
                        <div className="row align-items-start">
                          <div className="col-md-6">
                            <div className="team-photo">
                              <img
                                src="images/team/foto_maldo.jpg"
                                alt="team"
                              />
                            </div>
                          </div>
                          {/* .col  */}
                          <div className="col-md-6">
                            <div className="team-popup-info pl-md-3">
                              <h3 className="team-name title title-lg pt-4">
                                Pablo Maldonado
                              </h3>
                              <p className="team-position">
                                CTO &amp; Software Engineer
                              </p>
                              <ul className="team-social mb-4">
                                <li>
                                  <a href="https://www.facebook.com/MethaBiofund/">
                                    <em className="fab fa-facebook-f" />
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.linkedin.com/in/pablo-maldonado-turci-10b17164/">
                                    <em className="fab fa-linkedin-in" />
                                  </a>
                                </li>
                              </ul>
                              <p>{translate("maldo-bio-1")}</p>
                              <p>{translate("maldo-bio-2")}</p>
                              <p>{translate("maldo-bio-3")}</p>
                              <p>{translate("maldo-bio-4")}</p>
                            </div>
                          </div>
                          {/* .col  */}
                        </div>
                        {/* .row  */}
                      </div>
                      {/* .team-popup  */}
                    </div>
                    {/* .col */}
                    <div className="col-lg-3 col-mb-6">
                      <div className="team team-s5">
                        <div className="team-photo team-photo-s1">
                          <img
                            src="images/team/dano-pepino.jpg"
                            alt="team"
                            className="no-bdrs"
                          />
                          <a
                            href="#team-popup-11"
                            className="team-show content-popup"
                          />
                        </div>
                        <h5 className="team-name title title-md">
                          Daniel <br />
                          Fernández
                        </h5>
                        <span className="team-position">
                          CSO &amp; Business Dev &amp; Co-Founder
                        </span>
                        <ul className="team-social team-social-vr team-social-s2">
                          <li>
                            <a href="https://www.facebook.com/MethaBiofund/">
                              <em className="fab fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="https://twitter.com/MBiofund">
                              <em className="fab fa-twitter" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <em className="fab fa-linkedin-in" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* Start .team-profile  */}
                      <div id="team-popup-11" className="team-popup mfp-hide">
                        <a title="Close" className="mfp-close">
                          ×
                        </a>
                        <div className="row align-items-start">
                          <div className="col-md-6">
                            <div className="team-photo">
                              <img src="images/team/dano-pepino.jpg" alt="team" />
                            </div>
                          </div>
                          {/* .col  */}
                          <div className="col-md-6">
                            <div className="team-popup-info pl-md-3">
                              <h3 className="team-name title title-lg pt-4">
                                Open Position
                              </h3>
                              <p className="team-position">
                              Business Dev &amp; Co-Founder
                              </p>
                              <ul className="team-social mb-4">
                                <li>
                                  <a href="https://www.facebook.com/MethaBiofund/">
                                    <em className="fab fa-facebook-f" />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <em className="fab fa-linkedin-in" />
                                  </a>
                                </li>
                              </ul>
                              <p>
                                If you are a Digital Marketing Specialist or a
                                talented business developper that is pasionated
                                with new technology and the chalenge of
                                adoption, this might position might be for you.
                              </p>
                              <p>
                                You will be a part of the executive team with
                                the responsability of developping business
                                involving the Metha Biofund Ecosystem. Thinking
                                of new possible interactions between actors that
                                could benefit the whole ecosystem.
                              </p>
                              <p>
                                You also will be in charge of developping a
                                marketing strategy and form a team to bring
                                adoption.
                              </p>
                            </div>
                          </div>
                          {/* .col  */}
                        </div>
                        {/* .row  */}
                      </div>
                      {/* .team-profile  */}
                    </div>
                    <div className="col-lg-3 col-mb-6">
                      <div className="team team-s5">
                        <div className="team-photo team-photo-s1">
                          <img
                            src="images/team/sq-d.jpg"
                            alt="team"
                            className="no-bdrs"
                          />
                          <a
                            href="#team-popup-12"
                            className="team-show content-popup"
                          />
                        </div>
                        <h5 className="team-name title title-md">
                          Open <br />
                          Position
                        </h5>
                        <span className="team-position">
                          Lawyer & Blockchain Compliance
                        </span>
                        <ul className="team-social team-social-vr team-social-s2">
                          <li>
                            <a href="https://www.facebook.com/MethaBiofund/">
                              <em className="fab fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="https://twitter.com/MBiofund">
                              <em className="fab fa-twitter" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <em className="fab fa-linkedin-in" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* Start .team-profile  */}
                      <div id="team-popup-12" className="team-popup mfp-hide">
                        <a title="Close" className="mfp-close">
                          ×
                        </a>
                        <div className="row align-items-start">
                          <div className="col-md-6">
                            <div className="team-photo">
                              <img src="images/team/d.jpg" alt="team" />
                            </div>
                          </div>
                          {/* .col  */}
                          <div className="col-md-6">
                            <div className="team-popup-info pl-md-3">
                              <h3 className="team-name title title-lg pt-4">
                                Open Position
                              </h3>
                              <p className="team-position">
                                Lawyer / Blockchain Compliance
                              </p>
                              <ul className="team-social mb-4">
                                <li>
                                  <a href="https://www.facebook.com/MethaBiofund/">
                                    <em className="fab fa-facebook-f" />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <em className="fab fa-linkedin-in" />
                                  </a>
                                </li>
                              </ul>
                              <p>
                                New technology allways leads to legal
                                uncertainty and loopholes. Your work will be to
                                allow Metha Biofund to allways stay up to date
                                and compliant on the regulatory spectrum.
                              </p>
                              <p>
                                If you have experience in legal regulations of
                                new technology and finance but you are also a
                                passionate about crypto and blockchain this
                                might be a possition for you.
                              </p>
                            </div>
                          </div>
                          {/* .col  */}
                        </div>
                        {/* .row  */}
                      </div>
                      {/* .team-profile  */}
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                {/* .block @e */}
              </div>
            </section>
            {/* // */}
            <section className="section my-background-stat">
              <div className="container">
                <div className="section-head section-head-s9 wide-md">
                  <h6
                    className="title title-xs title-s1 tc-primary animated"
                    data-animate="fadeInUp"
                    data-delay=".1"
                  >
                    {translate("partners-ticker")}
                  </h6>
                  <h2
                    className="title animated"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    {translate("partners-title")}
                  </h2>
                </div>
                {/* Block @s */}
                <div className="nk-block block-partners">
                  <ul className="partner-list partner-list-left partner-list-s3 flex-wrap">
                    <li
                      className="partner-logo partner-logo-s2 animated"
                      data-animate="fadeInUp"
                      data-delay=".3"
                    >
                      <a href="https://ethereum.org/">
                        <img src="images/partners/ethereum.png" alt="partner" />
                      </a>
                    </li>
                    <li
                      className="partner-logo partner-logo-s2 animated"
                      data-animate="fadeInUp"
                      data-delay=".35"
                    >
                      <a href="https://www.trufflesuite.com/drizzle">
                        <img src="images/partners/drizzle.png" alt="partner" />
                      </a>
                    </li>
                    <li
                      className="partner-logo partner-logo-s2 animated"
                      data-animate="fadeInUp"
                      data-delay=".4"
                    >
                      <a href="https://metamask.io/">
                        <img src="images/partners/metamask.png" alt="partner" />
                      </a>
                    </li>
                    <li
                      className="partner-logo partner-logo-s2 animated"
                      data-animate="fadeInUp"
                      data-delay=".45"
                    >
                      <a href="https://www.trufflesuite.com/truffle">
                        <img src="images/partners/truffle.png" alt="partner" />
                      </a>
                    </li>
                    <li
                      className="partner-logo partner-logo-s2 animated"
                      data-animate="fadeInUp"
                      data-delay=".5"
                    >
                      <a href="https://openzeppelin.com//">
                        <img
                          src="images/partners/openzeppelin.png"
                          alt="partner"
                        />
                      </a>
                    </li>
                    {/*
                                        <li className="partner-logo partner-logo-s2 animated" data-animate="fadeInUp" data-delay=".55">
                                            <img src="images/partners/a-xs-alt.png" alt="partner" />
                                        </li>
                                        <li className="partner-logo partner-logo-s2 animated" data-animate="fadeInUp" data-delay=".6">
                                            <img src="images/partners/a-sm-alt.png" alt="partner" />
                                        </li>
                                        <li className="partner-logo partner-logo-s2 animated" data-animate="fadeInUp" data-delay=".65">
                                            <img src="images/partners/b-sm-alt.png" alt="partner" />
                                        </li>
                                        <li className="partner-logo partner-logo-s2 animated" data-animate="fadeInUp" data-delay=".7">
                                            <img src="images/partners/c-sm-alt.png" alt="partner" />
                                        </li>
                                        <li className="partner-logo partner-logo-s2 animated" data-animate="fadeInUp" data-delay=".75">
                                            <img src="images/partners/a-sm-alt.png" alt="partner" />
                                        </li>
                                        */}
                  </ul>
                </div>
                {/* Block @e */}
              </div>
            </section>
            {/* // */}
            {/* // */}
            <section className="section my-background-stat">
              <div className="container">
                <div className="section-head section-head-s9 wide-md">
                  <h6
                    className="title title-xs title-s1 tc-primary animated"
                    data-animate="fadeInUp"
                    data-delay=".1"
                  >
                    {translate("featured-ticker")}
                  </h6>
                  <h2
                    className="title animated"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    {translate("featured-title")}
                  </h2>
                </div>
                {/* Block @s */}
                <div className="nk-block block-partners">
                  <ul className="partner-list partner-list-left partner-list-s3 flex-wrap">
                    <li
                      className="partner-logo partner-logo-s2 animated"
                      data-animate="fadeInUp"
                      data-delay=".5"
                    >
                      <a href="https://stateofthedapps.com/">
                        <img
                          src="images/partners/stateofthedapps.png"
                          alt="partner"
                        />
                      </a>
                    </li>
                    {/*
                                        <li className="partner-logo partner-logo-s2 animated" data-animate="fadeInUp" data-delay=".55">
                                            <img src="images/partners/a-xs-alt.png" alt="partner" />
                                        </li>
                                        <li className="partner-logo partner-logo-s2 animated" data-animate="fadeInUp" data-delay=".6">
                                            <img src="images/partners/a-sm-alt.png" alt="partner" />
                                        </li>
                                        <li className="partner-logo partner-logo-s2 animated" data-animate="fadeInUp" data-delay=".65">
                                            <img src="images/partners/b-sm-alt.png" alt="partner" />
                                        </li>
                                        <li className="partner-logo partner-logo-s2 animated" data-animate="fadeInUp" data-delay=".7">
                                            <img src="images/partners/c-sm-alt.png" alt="partner" />
                                        </li>
                                        <li className="partner-logo partner-logo-s2 animated" data-animate="fadeInUp" data-delay=".75">
                                            <img src="images/partners/a-sm-alt.png" alt="partner" />
                                        </li>
                                        */}
                  </ul>
                </div>
                {/* Block @e */}
              </div>
            </section>
            {/* // */}
            <section className="section bg-light" id="faqs">
              <div className="container">
                <div className="section-head section-head-s9 wide-md">
                  <h6
                    className="title title-xs title-s1 tc-primary animated"
                    data-animate="fadeInUp"
                    data-delay=".1"
                  >
                    {translate("faq-ticker")}
                  </h6>
                  <h2
                    className="title animated"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    {translate("faq-title")}
                  </h2>
                  <div className="wide-sm">
                    <p
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".3"
                    >
                      {translate("faq-sub")}
                    </p>
                  </div>
                </div>
                {/* Block @s */}
                <div className="nk-block">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-12">
                      <ul
                        className="nav tab-nav tab-nav-btn pdb-r justify-content-start animated"
                        data-animate="fadeInUp"
                        data-delay=".4"
                      >
                        <li>
                          <a
                            className="active"
                            data-toggle="tab"
                            href="#general-questions-13"
                          >
                            {translate("faq-metha")}
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#ico-questions-13">
                            {translate("faq-ecosystem")}
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#tokens-sales-13">
                            {translate("faq-research")}
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* .col */}
                    <div className="col-lg-8">
                      <div
                        className="tab-content animated"
                        data-animate="fadeInUp"
                        data-delay=".5"
                      >
                        <div
                          className="tab-pane fade show active"
                          id="general-questions-13"
                        >
                          <div className="accordion accordion-faq" id="faq-47">
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm"
                                data-toggle="collapse"
                                data-target="#faq-47-1"
                              >
                                {translate("faq-metha-1-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-47-1"
                                className="collapse show"
                                data-parent="#faq-47"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-metha-1-a")}</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm collapsed"
                                data-toggle="collapse"
                                data-target="#faq-47-2"
                              >
                                {translate("faq-metha-2-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-47-2"
                                className="collapse"
                                data-parent="#faq-47"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-metha-2-a")}</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm collapsed"
                                data-toggle="collapse"
                                data-target="#faq-47-3"
                              >
                                {translate("faq-metha-3-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-47-3"
                                className="collapse"
                                data-parent="#faq-47"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-metha-3-a")}</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm collapsed"
                                data-toggle="collapse"
                                data-target="#faq-47-4"
                              >
                                {translate("faq-metha-4-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-47-4"
                                className="collapse"
                                data-parent="#faq-47"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-metha-4-a")}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="ico-questions-13">
                          <div className="accordion accordion-faq" id="faq-48">
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm"
                                data-toggle="collapse"
                                data-target="#faq-48-1"
                              >
                                {translate("faq-ecosystem-1-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-48-1"
                                className="collapse show"
                                data-parent="#faq-48"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-ecosystem-1-a")}</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm collapsed"
                                data-toggle="collapse"
                                data-target="#faq-48-2"
                              >
                                W{translate("faq-ecosystem-2-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-48-2"
                                className="collapse"
                                data-parent="#faq-48"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-ecosystem-2-a")}</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm collapsed"
                                data-toggle="collapse"
                                data-target="#faq-48-3"
                              >
                                {translate("faq-ecosystem-3-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-48-3"
                                className="collapse"
                                data-parent="#faq-48"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-ecosystem-3-a")}</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm collapsed"
                                data-toggle="collapse"
                                data-target="#faq-48-4"
                              >
                                {translate("faq-ecosystem-4-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-48-4"
                                className="collapse"
                                data-parent="#faq-48"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-ecosystem-4-a")}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="tokens-sales-13">
                          <div className="accordion accordion-faq" id="faq-49">
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm"
                                data-toggle="collapse"
                                data-target="#faq-49-1"
                              >
                                {translate("faq-research-1-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-49-1"
                                className="collapse show"
                                data-parent="#faq-49"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-research-1-a")}</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm collapsed"
                                data-toggle="collapse"
                                data-target="#faq-49-2"
                              >
                                {translate("faq-research-2-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-49-2"
                                className="collapse"
                                data-parent="#faq-49"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-research-2-a")}</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm collapsed"
                                data-toggle="collapse"
                                data-target="#faq-49-3"
                              >
                                {translate("faq-research-3-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-49-3"
                                className="collapse"
                                data-parent="#faq-49"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-research-3-a")}</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item accordion-item-s2 bg-white">
                              <h5
                                className="accordion-title accordion-title-sm collapsed"
                                data-toggle="collapse"
                                data-target="#faq-49-4"
                              >
                                {translate("faq-research-4-q")}
                                <span className="accordion-icon accordion-icon-s2" />
                              </h5>
                              <div
                                id="faq-49-4"
                                className="collapse"
                                data-parent="#faq-49"
                              >
                                <div className="accordion-content">
                                  <p>{translate("faq-research-4-a")}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-lg-4">
                      <div
                        className="nk-block-img mt-4 mt-lg-0 animated"
                        data-animate="fadeInUp"
                        data-delay=".6"
                      >
                        <img src="images/gfx/gfx-p.png" alt="lungwort" />
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                {/* .block @e */}
              </div>
            </section>
            {/* // */}
            <section
              className="section section-contact my-background-stat ov-h"
              id="contact"
            >
              <div className="container">
                {/* Block @s */}
                <div className="nk-block block-contact">
                  <div className="row justify-content-center gutter-vr-30px">
                    <div className="col-lg-4">
                      <div className="pdt-s">
                        <div className="d-flex flex-column h-100">
                          <p>{translate("contact-sub")}</p>
                          <ul className="contact-list contact-list-s3">
                            <li>
                              <em className="contact-icon contact-icon-s2 fas fa-phone" />
                              <div className="contact-text">
                                <span>+34 638 051 350</span>
                              </div>
                            </li>
                            <li>
                              <em className="contact-icon contact-icon-s2 fas fa-envelope" />
                              <div className="contact-text">
                                <span>info@metha.life</span>
                              </div>
                            </li>
                            <li>
                              <em className="contact-icon contact-icon-s2 fas fa-paper-plane" />
                              <div className="contact-text">
                                <span>Join us on Telegram</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                    <div className="col-lg-6">
                      <div className>
                        <form
                          className="contact-form"
                          action="form/contact.php"
                          method="post"
                        >
                          <div className="field-item">
                            <input
                              name="contact-name"
                              type="text"
                              className="input-line required"
                            />
                            <label className="field-label field-label-line">
                              Your Name
                            </label>
                          </div>
                          <div className="field-item">
                            <input
                              name="contact-email"
                              type="email"
                              className="input-line required email"
                            />
                            <label className="field-label field-label-line">
                              Your Email
                            </label>
                          </div>
                          <div className="field-item">
                            <textarea
                              name="contact-message"
                              className="input-line input-textarea required"
                              defaultValue={""}
                            />
                            <label className="field-label field-label-line">
                              Your Message
                            </label>
                          </div>
                          <input
                            type="text"
                            className="d-none"
                            name="form-anti-honeypot"
                            defaultValue
                          />
                          <div className="row">
                            <div className="col-sm-4">
                              <button
                                type="submit"
                                className="btn btn-md btn-primary"
                              >
                                Submit
                              </button>
                            </div>
                            <div className="col-sm-8">
                              <div className="form-results" />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                {/* .block @e */}
              </div>
            </section>
          </main>
          <footer className="nk-footer my-background-stat ">
            <section className="section py-0">
              <div className="container">
                {/* Block @s */}
                <div className="nk-block">
                  <div
                    className="bg-grad-alt round subscribe-wrap tc-light animated"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    <div className="row text-center text-md-left justify-content-center align-items-center gutter-vr-25px">
                      <div className="col-lg-6">
                        <div className="wide-auto-sm">
                          <h4 className="title-sm">
                            {translate("newsletter-title")}
                          </h4>
                          <p>{translate("newsletter-sub")}</p>
                        </div>
                      </div>
                      {/* .col */}
                      <div className="col-lg-6">
                        <div className="gap-3x d-none d-lg-block" />
                        <form
                          action="form/subscribe.php"
                          className="nk-form-submit"
                          method="post"
                        >
                          <div className="field-inline field-inline-s2 field-inline-s2-sm bg-white shadow-soft">
                            <div className="field-wrap">
                              <input
                                className="input-solid input-solid-md required email"
                                type="text"
                                name="contact-email"
                                placeholder="Enter your email"
                              />
                              <input
                                type="text"
                                className="d-none"
                                name="form-anti-honeypot"
                                defaultValue
                              />
                            </div>
                            <div className="submit-wrap">
                              <button className="btn btn-md btn-secondary">
                                {translate("button-subscribe")}
                              </button>
                            </div>
                          </div>
                          <div className="form-results" />
                        </form>
                      </div>
                      {/* .col */}
                    </div>
                  </div>
                </div>
                {/* .block @e */}
              </div>
              <div className="nk-ovm ovm-top ovm-h-50 my-background-stat bdb ov-h" />
              {/* .nk-ovm */}
            </section>
            <div className="section section-footer section-m bg-transparent">
              <div className="container">
                {/* Block @s */}
                <div className="nk-block block-footer">
                  <div className="row">
                    <div className="col">
                      <div className="wgs wgs-text text-center mb-3">
                        <ul className="social pdb-l justify-content-center">
                          <li>
                            <a href="https://www.facebook.com/MethaBiofund/">
                              <em className="social-icon fab fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="https://twitter.com/MBiofund">
                              <em className="social-icon fab fa-twitter" />
                            </a>
                          </li>
                          <li>
                            <a href="https://www.youtube.com/channel/UCDYTzGipU1cexjUYhKK8ifQ">
                              <em className="social-icon fab fa-youtube" />
                            </a>
                          </li>
                          <li>
                            <a href="https://github.com/Metha-Biofund">
                              <em className="social-icon fab fa-github" />
                            </a>
                          </li>
                          <li>
                            <a href="https://bitcointalk.org/">
                              <em className="social-icon fab fa-bitcoin" />
                            </a>
                          </li>
                          <li>
                            <a href="https://t.me/methabiofund">
                              <em className="social-icon fab fa-telegram" />
                            </a>
                          </li>
                        </ul>

                        <a href="./" className="footer-logo">
                          <img
                            className="foot-img"
                            rc="images/logo.png"
                            srcSet="images/logo2x.png 2x"
                            alt="logo"
                          />
                        </a>
                        <div className="copyright-text copyright-text-s3 pdt-m">
                          <p>
                            <span className="d-sm-block">
                              Copyright © 2018 - 2020, Metha Biofund.
                            </span>
                            All trademarks and copyrights belong to their
                            respective owners.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                </div>
                {/* .block @e */}
              </div>
              <div className="nk-ovm shape-s-sm shape-center-bottom ov-h" />
            </div>
          </footer>
        </div>
        <div className="preloader">
          <span className="spinner spinner-round" />
        </div>
      </div>
    </I18nProvider>
  );
}

export default withContext(Landing);
