import React from "react";
import useScript from '../../hooks/useScript';
import { withContext } from '../../context';
import Countdown from '../Countdown';

export default withContext((props) => {
    useScript("assets/js/scripts.js");
    return (
        <div>
            <section className="section my-background-stat" id="token">
                {/* Block @s */}
                <div className="container">
                    <div className="section-head section-head-s9 wide-md">
                        <h6 className="title title-xs title-s1 tc-primary animated" data-animate="fadeInUp" data-delay=".1">
                            Token</h6>
                        <h2 className="title animated" data-animate="fadeInUp" data-delay=".2">Token Sale</h2>
                        <div className="wide-sm">
                            <p className="animated" data-animate="fadeInUp" data-delay=".3">The tokens will be available for
                claiming after the end of each period.</p>
                        </div>
                    </div>
                    <div className="tab-content nk-preview-content">
                        <div className="tab-pane fade show active" id="token-default-01">
                            {/* Copy from here */}
                            <section className="bg-white">
                                <div className="section-l section-tokensale">
                                    <div className="container">
                                        {/* Block @s */}
                                        <div className="nk-block nk-block-token mgb-m30">
                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="token-info bg-white">
                                                        <h4 className="title title-md mb-2 text-sm-center">Token Info</h4>
                                                        <table className="table table-token">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="table-head">Next Period </td>
                                                                    <td className="table-des">15th Nov 2020 12:00 GMT</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="table-head">Meth / Period</td>
                                                                    <td className="table-des">9000 METH</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="table-head">Total METH</td>
                                                                    <td className="table-des">330,000</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="table-head">Total Raised</td>
                                                                    <td className="table-des">35.541,02 ETH</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="table-head">Meth Address</td>
                                                                    <td className="table-des">0xace3d5C7B77FfE453b1631f04632Ea1eCBaD85D1</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="table-head">Sale Address</td>
                                                                    <td className="table-des">0x7C13CCaca245176D7490B16401728149d696992E</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="table-head">Token Value</td>
                                                                    <td className="table-des">1 ETH = 9,8 METH</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="table-head">Accepted</td>
                                                                    <td className="table-des">ETH</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>{/* .col */}
                                                <div className="col-lg-5">
                                                    <div className="token-status bg-white">
                                                        <h4 className="title title-md">Period {props.current_period} ends in :</h4>
                                                        <div className="countdown justify-content-center justify-content-sm-start countdown-s3 countdown-s3-alt">
                                                            <Countdown start_time_of_next_period={props.start_time_of_next_period} />
                                                        </div>
                                                        <div className="progress-wrap progress-wrap-point">
                                                            <span className="progress-info">Raised so far <span>1.830 ETH</span></span>
                                                            <div className="progress-bar">
                                                                <div className="progress-percent bg-grad" data-percent={30} />
                                                                <div className="progress-point progress-point-1">Min <span>1.750 ETH</span></div>
                                                                <div className="progress-point progress-point-2">Max <span>4.750 ETH</span></div>
                                                            </div>
                                                        </div>
                                                        <a href="#" className="btn btn-grad">Contribute Now</a>
                                                        <span className="token-min">Minimum Contribution: 0,1 ETH</span>
                                                    </div>
                                                    <div className="bonus bg-white">
                                                        <div className="bonus-info">
                                                            <div className="bonus-percent">{props.current_period}th <small>Period</small></div>
                                                            <div className="bonus-date">End at 30 Nov, 2020</div>
                                                            <span className="bonus-badge badge-xs">Now</span>
                                                        </div>
                                                        <div className="bonus-info">
                                                            <div className="bonus-percent">{Number(props.current_period) + 1}th <small>Period</small></div>
                                                            <div className="bonus-date">Start at 01 Dec, 2020</div>
                                                        </div>
                                                    </div>
                                                </div>{/* .col */}
                                            </div>{/* .row */}
                                        </div>{/* .block @e */}
                                    </div>
                                </div>{/* .section-tokensale */}
                            </section>
                            {/* Stop here */}
                        </div>
                    </div>{/* .block @e */}
                    <div className="nk-block">
                        <div className="bg-white">
                            <div className="section section-tokendes">
                                <div className="container">
                                    {/* Block @s */}
                                    <div className="nk-block nk-block-alocation mgb-m30">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="token-alocate-item">
                                                    <div className="token-alocate-graph">
                                                        <span>Token <br /> Allocation</span>
                                                        <canvas className="chart-canvas" id="token-alocate" />
                                                    </div>
                                                    <ul className="chart-data" data-canvas="token-alocate" data-canvas-type="doughnut">
                                                        <li data-color="#223fa8" data-title="Token Sale Program" data-amount={60} />
                                                        <li data-color="#8fa0df" data-title="Reserve Fund" data-amount={8} />
                                                        <li data-color="#4aa0f6" data-title="Team and Founders" data-amount={15} />
                                                        <li data-color="#6ad9ac" data-title="Board Advisors" data-amount={4} />
                                                        <li data-color="#4d6fe9" data-title="Ecosystem Development" data-amount={7} />
                                                        <li data-color="#15216d" data-title="Marketing and Bounty" data-amount={6} />
                                                    </ul>
                                                </div>
                                            </div>{/* .col */}
                                            <div className="col-lg-6">
                                                <div className="token-alocate-item">
                                                    <div className="token-alocate-graph">
                                                        <span>Funds <br /> Allocation</span>
                                                        <canvas className="chart-canvas" id="fund-alocate" />
                                                    </div>
                                                    <ul className="chart-data" data-canvas="fund-alocate">
                                                        <li data-color="#15216d" data-title="Engineering and Development" data-amount={40} />
                                                        <li data-color="#4aa0f6" data-title="Business Development" data-amount={12} />
                                                        <li data-color="#223fa8" data-title="Marketing & Promotion" data-amount={20} />
                                                        <li data-color="#72a3f4" data-title="Legal & Regulation" data-amount={10} />
                                                        <li data-color="#6ad9ac" data-title="Operational & Administration" data-amount={8} />
                                                        <li data-color="#8fa0df" data-title="Contingency" data-amount={4} />
                                                        <li data-color="#4d6fe9" data-title="Partners" data-amount={6} />
                                                    </ul>
                                                </div>
                                            </div>{/* .col */}
                                        </div>{/* .row */}
                                    </div>{/* .block @e */}
                                </div>
                            </div>{/* .section-tokendes */}
                        </div>
                        {/* Stop here */}
                    </div>{/* .block @e */}
                </div>
            </section>
        </div>
    )
})
