import MethaToken from "./contracts/MethaToken.json";
import MethaCrowdsale from "./contracts/MethaCrowdsale.json";

const options = {
    web3: {
        block: false,
        fallback: {
            type: "ws",
            url: "ws://34.245.41.145:9545",
        },
    },
    contracts: [MethaToken, MethaCrowdsale],
    events: {
        MethaCrowdsale: ["Mbuy"],
    },
    polls: {
        // set polling interval to 30secs so we don't get buried in poll events
        accounts: 30000,
    },
};

export default options;
